@charset 'UTF-8';

$color-gray   : #F6F6F6;
$color-gray2  : #DDDDDD;
$color-gray3  : #444444;

$border-color : $color-gray2;

$txt-gray     : $color-gray3;


$font-gothic  : 'Hiragino Kaku Gothic ProN', '游ゴシック', 'メイリオ', meiryo, sans-serif;
$font-mincho  : '游明朝', YuMincho, 'Hiragino Mincho ProN', 'Hiragino Mincho Pro', 'ＭＳ 明朝', serif;


*, *:before, *:after{
	box-sizing: border-box;
}

html{
	font-size: 10px;
	font-family: $font-gothic;
}

body{
	-webkit-text-size-adjust: 100%;
	font-size: 1.2rem;
  color: #333333;
  word-break: break-all;

  @include breakpoint(pc){
    font-size: 1.4rem;
  }
}

img{
	max-width: none;
	width: 100%;
	height: auto;
}

a{
  text-decoration: none;

  &:hover{
    text-decoration: underline;

    img{
      opacity: 0.8;
    }
  }
}

form, input{
  font-family: $font-gothic;
  border: none;
}
