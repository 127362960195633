@charset "UTF-8";

.c-searchBox{
  background-color: #FAFAFA;
  border: 1px solid #DDDDDD;
  padding: 15px;
  margin: 0 auto 15px;

  @include breakpoint(pc){}

  &__selects{

    @include breakpoint(pc){
      width: 100%;
      display: table;
      margin: 0 -10px 15px;
    }
  }

  &__select{
    margin: 0 0 10px;

    @include breakpoint(pc){
      display: table-cell;
      width: 25%;
      padding: 0 10px;
      margin: 0;
    }

    select{
      display: block;
      width: 100%;
      height: 1.6em;
      background: none;
      border: 1px solid #DDDDDD;
      border-radius: 0;
      background-color: #FFFFFF;
      font-size: 16px;
      font-family: $font-gothic;
    }

    option{
      font-family: $font-gothic;
    }
  }

  &__bottom{

    @include breakpoint(pc){
      width: 100%;
      display: table;
      margin: 0 -10px;
    }
  }

  &__radio{
    font-size: 1.4rem;
    margin: 0 0 10px;

    @include breakpoint(pc){
      display: table-cell;
      padding: 0 10px;
      width: 30%;
      margin: 0;
      vertical-align: middle;
    }

    span{
      display: inline-block;
      border-right: 1px solid #DDDDDD;
      padding: 10px 20px 10px 10px;
    }

    label{
      display: inline-block;
      padding: 10px;
      font-size: 1.4rem;
    }

    input{
      display: inline-block;
    }
  }

  &__text{
    margin: 0 0 10px;

    @include breakpoint(pc){
      display: table-cell;
      padding: 0 10px;
      width: 35%;
      margin: 0;
      vertical-align: middle;
    }

    input{
      display: block;
      width: 100%;
      padding: 6px 10px;
      font-size: 1.6rem;
      border: 1px solid #DDDDDD;
    }
  }

  &__submit{

    @include breakpoint(pc){
      display: table-cell;
      padding: 0 10px;
      width: 35%;
      vertical-align: middle;
    }

    input{
      display: block;
      width: 100%;
      padding: 10px;
      font-size: 1.4rem;

      @extend .c-btn;
      @extend .c-btn--flat;
    }
  }
}
