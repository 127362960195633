@charset "UTF-8";

.c-inner{
  @include cfx;
  position: relative;
  padding: 0 10px;

  @include breakpoint(pc){
    width: 1020px;
    padding: 0 30px;
    margin: 0 auto;
  }
}

.c-inner--flex{
  @include cfx;
  position: relative;
  padding: 0 10px;

  @include breakpoint(pc){
    max-width: 1200px;
    min-width: 1020px;
    padding: 0 30px;
    margin: 0 auto;
  }
}



