@charset "UTF-8";

.p-con{

  @include breakpoint(pc){
  }

  input{
    width: 100%;
  }

  .c-table{

    tr{
      display: block;

      @include breakpoint(pc){
        display: table-row;
      }
    }

    th{
      display: block;
      width: 100%;
      border: none;

      @include breakpoint(pc){
        width: 300px;
        display: table-cell;
        border: 1px solid #CCCCCC;
        border-bottom: 1px solid #fff;
      }
    }

    td{
      display: block;
      width: 100%;
      border: none;

      @include breakpoint(pc){
        width: auto;
        display: table-cell;
        border-left: 1px solid #CCCCCC;
        border-bottom: 1px solid #CCCCCC;
      }
    }

    input[type="radio"]{
      display: inline-block;
      width: auto;
      margin: 0 10px 0 5px;
    }

    input[type="text"]{
      display: inline-block;
      width: 100%;
      margin: 0 0 5px;
      padding: 6px 10px;
      font-size: 1.6rem;
      border: 1px solid #DDDDDD;

      &.is-number--small{
        display: inline-block;
        width: 20%;
        margin: 0 10px 5px;
      }
      &.is-number--wide{
        display: inline-block;
        width: 24%;
        margin: 0 10px 5px;
      }
    }

    select{
      display: block;
      width: 100%;
      height: 1.6em;
      background: none;
      border: 1px solid #DDDDDD;
      border-radius: 0;
      background-color: #FFFFFF;
      font-size: 16px;
      font-family: $font-gothic;
    }

    option{
      font-family: $font-gothic;
    }

    textarea{
      font-size: 16px;
      display: block;
      width: 100%;
      min-height: 3em;
    }
  }
}

