@charset "UTF-8";

.c-news{

  &__list{}

  &__item{
    border-bottom: 1px dotted #DDDDDD;
    margin: 0 0 20px;

    a{
      display: block;
      text-decoration: none;

      &:hover{
        .c-news__text{
          text-decoration: underline;
        }
      }
    }
  }

  &__date{
    color: #333333;
    font-weight: bold;
    font-size: 1;
    margin: 0 10px 0 0;

    @include breakpoint(pc){
      font-size: 1.4rem;
      margin: 0 15px 0 0;
    }
  }

  &__category{
    display: inline-block;
    vertical-align: baseline;
    width: 110px;
    padding: 4px 0 2px;
    text-align: center;
    border: 1px solid #000;
    font-size: 1rem;

    @include breakpoint(pc){
      width: 130px;
      font-size: 1.3rem;
    }

    &.is-blue{
      color: #00A3D9;
      border-color: #00A3D9;
    }

    &.is-green{
      color: #59B200;
      border-color: #59B200;
    }

    &.is-orange{
      color: #FF2209;
      border-color: #FF2209;
    }

    &.is-navy{
      color: #344796;
      border-color: #344796;
    }

    &.is-gray{
      color: #666666;
      border-color: #666666;
    }
  }

  &__text{
    font-size: 1.4rem;
    line-height: 1.6;
    padding: 10px 0;
    background-image: url('../images/common/icon_arrow_right.png');
    background-repeat: no-repeat;
    background-position: left 17px;
    background-size: 5px 8px;
    padding: 10px 0 15px 13px;

    @include breakpoint(pc){
      font-size: 1.6rem;
    }
  }
}






