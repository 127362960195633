@charset "UTF-8";
/*doc
---
category: index
---

# js関連

## 「.js-mh」もしくは「(data-match-height="groupName")」を当てると要素の高さが揃います。

## aタグに「.js-scroll」をつけるとスムーススクロールします。

*/
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, abbr, address, cite, code, del, dfn, em,
img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i,
dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table,
caption, tbody, tfoot, thead, tr, th, td, article, aside,
canvas, details, figcaption, figure, footer, header, hgroup,
menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

html, body {
  line-height: 1;
}

main, article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ul, ol {
  list-style: none;
}

li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

a:hover {
  text-decoration: none;
}

a, span {
  color: inherit;
  font-weight: inherit;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

sup {
  vertical-align: super;
}

/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
}

input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none;
}

input[type="submit"]::focus,
input[type="button"]::focus {
  outline-offset: -2px;
}

*, *:before, *:after {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  font-family: "Hiragino Kaku Gothic ProN", "游ゴシック", "メイリオ", meiryo, sans-serif;
}

body {
  -webkit-text-size-adjust: 100%;
  font-size: 1.2rem;
  color: #333333;
  word-break: break-all;
}

@media (min-width: 769px) {
  body {
    font-size: 1.4rem;
  }
}

img {
  max-width: none;
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:hover img {
  opacity: 0.8;
}

form, input {
  font-family: "Hiragino Kaku Gothic ProN", "游ゴシック", "メイリオ", meiryo, sans-serif;
  border: none;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.l-wrap {
  width: 100%;
  overflow: hidden;
  padding: 60px 0 0;
}

@media (min-width: 769px) {
  .l-wrap {
    padding: 0;
  }
}

.l-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  max-height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  background-color: #fff;
}

@media (min-width: 769px) {
  .l-header {
    position: static;
    overflow: visible;
  }
}

.l-header__sp {
  display: block;
}

@media (min-width: 769px) {
  .l-header__sp {
    display: none;
  }
}

.l-header__pc {
  display: none;
}

@media (min-width: 769px) {
  .l-header__pc {
    display: block;
    float: right;
    position: relative;
    z-index: 2;
  }
  .l-header__pc:after {
    content: '';
    display: block;
    clear: both;
  }
}

.l-header__blockLeft {
  float: left;
  padding: 0 20px 0 0;
}

.l-header__main {
  height: 60px;
  position: relative;
  border-bottom: 1px solid #DDDDDD;
}

@media (min-width: 769px) {
  .l-header__main {
    height: 80px;
  }
}

.l-header__logo {
  font-size: 1rem;
  padding: 20px 0 0 7px;
}

@media (min-width: 769px) {
  .l-header__logo {
    padding: 20px 0 0 30px;
    font-size: 1.1rem;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.l-header__logo img {
  width: 100px;
  display: inline-block;
  margin: 0 6px 0 0;
  vertical-align: bottom;
}

@media (min-width: 769px) {
  .l-header__logo img {
    width: 146px;
  }
}

.l-header__navBtn {
  position: absolute;
  top: 20px;
  right: 17px;
  width: 24px;
  height: 24px;
  background-image: url("../images/common/nav_btn_open.png");
  background-size: 100%;
}

.l-header__navBtn.is-open {
  background-image: url("../images/common/nav_btn_close.png");
}

@media (min-width: 769px) {
  .l-header__navBtn {
    display: none;
  }
}

.l-header__contents {
  display: none;
  background-color: #6C7680;
  margin: 0 -10px;
  padding: 15px 10px 20px;
}

.l-header__contents.is-show {
  display: block;
}

@media (min-width: 769px) {
  .l-header__contents {
    display: block;
    background-color: #fff;
    margin: 0;
    padding: 0;
  }
}

.l-header__tel {
  margin: 0 0 8px;
  font-size: 1.6rem;
}

@media (min-width: 769px) {
  .l-header__tel {
    text-align: right;
    color: #333333;
    font-size: 2.4rem;
    font-weight: bold;
  }
}

.l-header__btns {
  margin: 0 0 15px;
}

.l-header__btns:after {
  content: '';
  display: block;
  clear: both;
}

@media (min-width: 769px) {
  .l-header__btns {
    float: left;
    width: 421px;
    margin: 0;
  }
}

.l-header__btns li {
  float: left;
  width: 49.6%;
  background-color: #6697DC;
  border-radius: 4px;
}

@media (min-width: 769px) {
  .l-header__btns li {
    width: 210px;
    border-radius: 0;
    transition: 0.5s;
  }
  .l-header__btns li:hover {
    opacity: 0.8;
  }
}

.l-header__btns li:last-child {
  float: right;
  background-color: #FB5227;
}

.l-header__btns li a {
  display: block;
  text-align: center;
  color: #fff;
  text-decoration: none;
  font-size: 1.6rem;
  line-height: 55px;
}

@media (min-width: 769px) {
  .l-header__btns li a {
    display: block;
    font-size: 1.8rem;
    line-height: 80px;
  }
}

.l-header__list {
  margin: 0 0 10px;
}

@media (min-width: 769px) {
  .l-header__list {
    display: table;
  }
}

.l-header__item {
  background-color: #fff;
  border-bottom: 1px solid #DDDDDD;
}

@media (min-width: 769px) {
  .l-header__item {
    display: table-cell;
    width: 200px;
    border-collapse: separate;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    border-bottom: 2px solid #DDDDDD;
  }
}

.l-header__item:last-child {
  border-bottom: none;
}

.l-header__item > a {
  display: block;
  line-height: 51px;
  padding: 0 30px 0 17px;
  background-image: url("../images/common/icon_arrow_right3.png");
  background-repeat: no-repeat;
  background-position: 95% center;
  background-position: right 20px center;
  background-size: 10px 14px;
  font-size: 1.6rem;
  text-decoration: none;
  font-weight: bold;
  color: #444444;
}

@media (min-width: 769px) {
  .l-header__item > a {
    padding: 0;
    text-align: center;
    font-weight: normal;
    background: none;
    line-height: 57px;
  }
  .l-header__item > a:hover {
    font-weight: bold;
  }
}

@media (min-width: 769px) {
  .l-header__item.current {
    border-bottom: 2px solid #7DA1CA;
  }
}

@media (min-width: 769px) {
  .l-header__item.current > a {
    font-weight: bold;
  }
}

@media (min-width: 769px) {
  .l-header__item.is-hasChild {
    position: relative;
  }
}

.l-header__item.is-hasChild.is-show {
  background-color: #F6F6F6;
}

@media (min-width: 769px) {
  .l-header__item.is-hasChild.is-show {
    background-color: #fff;
  }
}

.l-header__item.is-hasChild > a {
  background-image: url("../images/common/icon_arrow_down2.png");
  background-position: 95% center;
  background-position: right 19px center;
  background-size: 14px 10px;
}

@media (min-width: 769px) {
  .l-header__item.is-hasChild > a {
    background: none;
  }
}

.l-header__item.is-hasChild > a.is-open {
  background-image: url("../images/common/icon_arrow_up2.png");
}

@media (min-width: 769px) {
  .l-header__item.is-hasChild > a.is-open {
    background: none;
  }
}

.l-header__childList {
  display: none;
  padding: 0 17px 15px;
  background-color: #fff;
}

.l-header__childList.is-show {
  display: block;
}

@media (min-width: 769px) {
  .l-header__childList {
    width: 100%;
    padding: 0;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    border: 1px solid #DDDDDD;
  }
}

.l-header__childItem {
  margin: 0 0 10px;
}

@media (min-width: 769px) {
  .l-header__childItem {
    margin: 0;
  }
}

.l-header__childItem a {
  display: block;
  line-height: 34px;
  font-size: 1.6rem;
  padding: 0 0 0 20px;
  text-decoration: none;
  color: #444444;
  background-image: url("../images/common/icon_arrow_right2.png");
  background-repeat: no-repeat;
  background-position: 3px center;
  background-size: 5px 9px;
}

@media (min-width: 769px) {
  .l-header__childItem a {
    font-size: 1.3rem;
    background: none;
    padding: 0;
    line-height: 40px;
    text-align: center;
    border-top: 1px solid #DDDDDD;
  }
}

@media (min-width: 769px) {
  .l-header__langList {
    display: inline-block;
  }
}

.l-header__langItem {
  background-color: #fff;
  margin: 0 0 10px;
}

@media (min-width: 769px) {
  .l-header__langItem {
    background-color: transparent;
    display: inline-block;
    padding: 15px 10px;
    font-size: 1.2rem;
    margin: 0;
  }
}

.l-header__langItem:last-child {
  margin: 0;
}

.l-header__langItem > a {
  display: block;
  line-height: 40px;
  padding: 0 15px 0 30px;
  text-decoration: none;
  font-size: 1.4rem;
  color: #444444;
  font-weight: bold;
  background-image: url("../images/common/icon_arrow_right.png");
  background-repeat: no-repeat;
  background-position: 15px center;
  background-size: 5px 8px;
}

@media (min-width: 769px) {
  .l-header__langItem > a {
    font-size: 1.2rem;
    padding: 0;
    background: none;
    font-weight: normal;
    text-decoration: underline;
    line-height: 1;
  }
}

@media (min-width: 769px) {
  .l-header__faq {
    display: inline-block;
    color: #333333;
    padding: 0 0 0 10px;
  }
  .l-header__faq a {
    text-decoration: none;
    background-image: url("../images/common/icon_arrow_right.png");
    background-repeat: no-repeat;
    background-position: 0 center;
    background-size: 5px 8px;
    padding: 0 0 0 10px;
    font-size: 1.2rem;
    font-weight: bold;
  }
}

.l-breadcrumb {
  padding: 20px 0 8px;
  border-bottom: 2px solid #C8C8C8;
  margin: 0 0 5px;
  line-height: 1.6;
}

@media (min-width: 769px) {
  .l-breadcrumb {
    padding: 40px 0 15px;
    margin: 0 0 10px;
  }
}

.l-breadcrumb ul:after {
  content: '';
  display: block;
  clear: both;
}

.l-breadcrumb li {
  float: left;
  font-size: 1rem;
}

@media (min-width: 769px) {
  .l-breadcrumb li {
    font-size: 1.2rem;
  }
}

.l-breadcrumb li:before {
  content: '＞';
  display: inline-block;
  padding: 0 5px;
}

.l-breadcrumb li:first-child:before {
  display: none;
}

.l-sitemap {
  margin: 0 0 43px;
}

@media (min-width: 769px) {
  .l-sitemap:after {
    content: '';
    display: block;
    clear: both;
  }
}

@media (min-width: 769px) {
  .l-sitemap__col {
    width: 21.9%;
    float: left;
    margin: 0 1.7% 0 0;
  }
}

@media (min-width: 769px) {
  .l-sitemap__col--big {
    width: 28%;
    margin: 0 2.6% 0 0;
  }
}

.l-sitemap__col:last-child {
  margin: 0;
}

.l-sitemap__item {
  border: 1px solid #DDDDDD;
  background-color: #fff;
  margin: 0 0 10px;
}

.l-sitemap__item img {
  display: block;
}

.l-sitemap__item--big > a {
  display: table;
  width: 100%;
  padding: 4px;
  text-decoration: none;
  font-size: 1.6rem;
  line-height: 1.6;
  color: #444444;
  font-weight: bold;
}

.l-sitemap__item--big > a:after {
  content: '';
  display: block;
  clear: both;
}

@media (min-width: 769px) {
  .l-sitemap__item--big > a {
    font-size: 1.8rem;
    padding: 4px 6px;
  }
}

.l-sitemap__item--big span {
  display: table-cell;
  vertical-align: middle;
}

.l-sitemap__item--big span:first-child {
  width: 124px;
}

@media (min-width: 769px) {
  .l-sitemap__item--big span:first-child {
    width: 132px;
  }
}

.l-sitemap__item--big span:last-child {
  padding: 0 0 0 20px;
}

.l-sitemap__item--single > a {
  display: block;
  padding: 10px 10px 10px 25px;
  text-decoration: none;
  background-image: url("../images/common/icon_arrow_right.png");
  background-repeat: no-repeat;
  background-position: 15px 17px;
  background-size: 5px 8px;
  font-size: 1.4rem;
  line-height: 1.6;
  color: #444444;
  font-weight: bold;
}

@media (min-width: 769px) {
  .l-sitemap__item--single > a {
    padding: 12px 12px 12px 25px;
    background-position: 15px 19px;
  }
}

.l-sitemap__item--hasChild > a {
  display: block;
  padding: 10px 10px 10px 15px;
  text-decoration: none;
  background-image: url("../images/common/icon_arrow_down.png");
  background-repeat: no-repeat;
  background-position: 95% 17px;
  background-position: right 15px top 15px;
  background-size: 17px 11px;
  font-size: 1.4rem;
  line-height: 1.6;
  color: #444444;
  font-weight: bold;
}

@media (min-width: 769px) {
  .l-sitemap__item--hasChild > a {
    padding: 12px 12px 12px 25px;
    background-image: url("../images/common/icon_arrow_right.png");
    background-repeat: no-repeat;
    background-position: 15px 19px;
    background-size: 5px 8px;
  }
  .l-sitemap__item--hasChild > a.is-noLink:hover {
    text-decoration: none;
    cursor: default;
  }
}

.l-sitemap__item--hasChild > a.is-open {
  background-image: url("../images/common/icon_arrow_up.png");
}

@media (min-width: 769px) {
  .l-sitemap__item--hasChild > a.is-open {
    background-image: url("../images/common/icon_arrow_right.png");
  }
}

.l-sitemap__childList {
  display: none;
  padding: 0 20px 10px;
}

@media (min-width: 769px) {
  .l-sitemap__childList {
    display: block;
  }
}

.l-sitemap__childList.is-show {
  display: block;
}

.l-sitemap__childItem {
  margin: 0 0 12px;
}

.l-sitemap__childItem > a {
  padding: 0 0 0 10px;
  display: block;
  font-size: 1.4rem;
  line-height: 1.4;
  background-image: url("../images/common/icon_arrow_right2.png");
  background-repeat: no-repeat;
  background-position: 0 3px;
  background-size: 5px 9px;
}

@media (min-width: 769px) {
  .l-sitemap__childItem > a {
    line-height: 1.6;
    background-position: 5px 7px;
    padding: 0 0 0 15px;
  }
}

.l-footer {
  background-color: #F6F6F6;
  padding: 23px 0;
}

@media (min-width: 769px) {
  .l-footer {
    padding: 50px 0 60px;
  }
}

.l-footer a:hover {
  text-decoration: underline;
}

.l-footer__bottom {
  border-top: 1px solid #DDDDDD;
  padding: 20px 0 0;
}

@media (min-width: 769px) {
  .l-footer__bottom {
    padding: 30px 0 0;
  }
  .l-footer__bottom:after {
    content: '';
    display: block;
    clear: both;
  }
}

.l-footer__logo {
  margin: 0 0 18px;
  font-size: 1rem;
  font-weight: bold;
}

@media (min-width: 769px) {
  .l-footer__logo {
    float: left;
  }
}

.l-footer__logo img {
  width: 146px;
  display: inline-block;
  margin: 0 6px 0 0;
  vertical-align: bottom;
}

@media (min-width: 769px) {
  .l-footer__copy {
    float: right;
  }
}

.l-footer__copy p {
  color: #444444;
  line-height: 1.2;
  font-size: 1.4rem;
  margin: 5px 0;
}

.c-inner {
  position: relative;
  padding: 0 10px;
}

.c-inner:after {
  content: '';
  display: block;
  clear: both;
}

@media (min-width: 769px) {
  .c-inner {
    width: 1020px;
    padding: 0 30px;
    margin: 0 auto;
  }
}

.c-inner--flex {
  position: relative;
  padding: 0 10px;
}

.c-inner--flex:after {
  content: '';
  display: block;
  clear: both;
}

@media (min-width: 769px) {
  .c-inner--flex {
    max-width: 1200px;
    min-width: 1020px;
    padding: 0 30px;
    margin: 0 auto;
  }
}

/*doc
---
title: 1.カラム
name: 1.カラム
category: column
---

## 2カラム、等分です。

```html_example
<div class="c-column c-column--2">
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
</div>
```

*/
@media (min-width: 769px) {
  .c-column--2 {
    display: block;
    margin: 0 -20px;
  }
  .c-column--2:after {
    content: '';
    display: block;
    clear: both;
  }
}

.c-column--2 .c-column__item {
  margin-bottom: 10px;
}

@media (min-width: 769px) {
  .c-column--2 .c-column__item {
    display: block;
    width: 50%;
    float: left;
    padding: 0 20px;
  }
  .c-column--2 .c-column__item:nth-of-type(odd) {
    clear: both;
  }
}

/*doc
---
title: 2.カラム
name: 2.カラム
category: column
---

## 2カラム、左大きめです。

```html_example
<div class="c-column c-column--2a">
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
</div>
```

*/
@media (min-width: 769px) {
  .c-column--2a {
    display: table;
    margin: 0 -20px;
  }
}

.c-column--2a .c-column__item {
  margin-bottom: 10px;
}

@media (min-width: 769px) {
  .c-column--2a .c-column__item {
    display: table-cell;
    vertical-align: top;
    padding: 0 20px;
  }
  .c-column--2a .c-column__item:first-child {
    width: 65%;
  }
  .c-column--2a .c-column__item:last-child {
    width: 35%;
  }
}

/*doc
---
title: 3.カラム
name: 3.カラム
category: column
---

## 2カラム、右大きめです。

```html_example
<div class="c-column c-column--2b">
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
</div>
```

*/
@media (min-width: 769px) {
  .c-column--2b {
    display: table;
    display: block;
    margin: 0 -20px;
  }
  .c-column--2b:after {
    content: '';
    display: block;
    clear: both;
  }
}

.c-column--2b .c-column__item {
  margin-bottom: 10px;
}

@media (min-width: 769px) {
  .c-column--2b .c-column__item {
    vertical-align: top;
    display: table-cell;
    display: block;
    padding: 0 20px;
  }
  .c-column--2b .c-column__item:first-child {
    float: right;
    width: 65%;
  }
  .c-column--2b .c-column__item:last-child {
    float: left;
    width: 35%;
  }
}

@media (min-width: 769px) {
  .c-column--2bRev {
    display: table;
    display: block;
    margin: 0 -20px;
  }
  .c-column--2bRev:after {
    content: '';
    display: block;
    clear: both;
  }
}

@media (min-width: 769px) {
  .c-column--2bRev .c-column__item {
    vertical-align: top;
    display: table-cell;
    display: block;
    padding: 0 20px;
  }
  .c-column--2bRev .c-column__item:first-child {
    float: left;
    width: 35%;
  }
  .c-column--2bRev .c-column__item:last-child {
    float: right;
    width: 65%;
  }
}

/*doc
---
title: 4.カラム
name: 4.カラム
category: column
---

## 3カラム

```html_example
<div class="c-column c-column--3">
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
</div>
```

*/
@media (min-width: 769px) {
  .c-column--3 {
    margin: -20px -10px 0;
  }
  .c-column--3:after {
    content: '';
    display: block;
    clear: both;
  }
}

.c-column--3 .c-column__item {
  margin: 0 0 10px;
}

@media (min-width: 769px) {
  .c-column--3 .c-column__item {
    float: left;
    padding: 0 10px;
    margin: 20px 0 0;
    width: 33.3333333%;
  }
  .c-column--3 .c-column__item:nth-of-type(3n+1) {
    clear: both;
  }
}

/*doc
---
title: 5.カラム
name: 5.カラム
category: column
---

## 4カラム

```html_example
<div class="c-column c-column--4">
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
</div>
```

*/
.c-column--4:after {
  content: '';
  display: block;
  clear: both;
}

@media (min-width: 769px) {
  .c-column--4 {
    margin: -20px -10px 0;
  }
}

.c-column--4 .c-column__item {
  float: left;
  width: 48%;
  margin: 0 0 10px;
}

.c-column--4 .c-column__item:nth-of-type(even) {
  float: right;
}

@media (min-width: 769px) {
  .c-column--4 .c-column__item {
    padding: 0 10px;
    margin: 20px 0 0;
    width: 25%;
    width: calc(100% / 4);
  }
  .c-column--4 .c-column__item:nth-of-type(even) {
    float: left;
  }
  .c-column--4 .c-column__item:nth-of-type(4n+1) {
    clear: both;
  }
}

/*doc
---
title: 6.カラム
name: 6.カラム
category: column
---

## 5カラム

```html_example
<div class="c-column c-column--5">
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
</div>
```

*/
.c-column--5:after {
  content: '';
  display: block;
  clear: both;
}

@media (min-width: 769px) {
  .c-column--5 {
    min-width: 100%;
    display: table;
    margin: -20px -10px 0;
  }
}

.c-column--5 .c-column__item {
  width: 49%;
  margin-bottom: 10px;
}

.c-column--5 .c-column__item:nth-of-type(odd) {
  clear: both;
  float: left;
}

.c-column--5 .c-column__item:nth-of-type(even) {
  float: right;
}

@media (min-width: 769px) {
  .c-column--5 .c-column__item {
    float: left;
    padding: 0 10px;
    margin: 20px 0 0;
    width: 20%;
    width: calc(100% / 5);
  }
  .c-column--5 .c-column__item:nth-of-type(odd) {
    clear: none;
    float: left;
  }
  .c-column--5 .c-column__item:nth-of-type(even) {
    float: left;
  }
  .c-column--5 .c-column__item:nth-of-type(5n+1) {
    clear: both;
  }
}

/*doc
---
title: 1.ブロック
name: 1.ブロック
category: block
---

## ブロックです。

```html_example
<div class="c-block--l" style="padding:20px;background-color:#ccc;">
  <p>Lサイズのブロックです。下にPC:70px、SP:35pxの余白があります。</p>
</div>
<div class="c-block--m" style="padding:20px;background-color:#ccc;">
  <p>Mサイズのブロックです。下にPC:50px、SP:25pxの余白があります。</p>
</div>
<div class="c-block--s" style="padding:20px;background-color:#ccc;">
  <p>Sサイズのブロックです。下にPC:25px、SP:15pxの余白があります。</p>
</div>
```

*/
.c-block--l {
  margin-bottom: 70px;
}

@media (min-width: 769px) {
  .c-block--l {
    margin-bottom: 70px;
  }
}

.c-block--m {
  margin-bottom: 50px;
}

@media (min-width: 769px) {
  .c-block--m {
    margin-bottom: 50px;
  }
}

.c-block--s {
  margin-bottom: 25px;
}

@media (min-width: 769px) {
  .c-block--s {
    margin-bottom: 25px;
  }
}

/*doc
---
title: 1.ボックス（ボーダー）
name: 1.ボックス（ボーダー）
category: box
---

## ボーダー付きボックス

```html_example
<div class="c-box--border">
  <p>コンテンツコンテンツコンテンツコンテンツコンテンツコンテンツコンテンツコンテンツコンテンツ</p>
</div>
```

*/
.c-box--border {
  display: block;
  border: 1px solid #C8C8C8;
  padding: 10px;
}

/*doc
---
title: 2.ボックス（ボーダーと背景）
name: 2.ボックス（ボーダーと背景）
category: box
---

## ボーダーと背景付きボックス

```html_example
<div class="c-box--bgc">
  <p>コンテンツコンテンツコンテンツコンテンツコンテンツコンテンツコンテンツコンテンツコンテンツ</p>
</div>
```

*/
.c-box--bgc {
  display: block;
  background-color: #FAFAFA;
  border: 1px solid #DDDDDD;
  padding: 15px 20px;
}

/*doc
---
title: 3.ボックス（電話番号）
name: 3.ボックス（電話番号）
category: box
---

## ボーダーと背景付きボックス

```html_example
<div class="c-box--tell">
  <div class="c-box--tell__tel">
    <p class="c-box--tell__ttl">フリーコール電話</p>
    <a class="c-box--tell__number" href="tel:0120453802">0120-453-802</a>
    <p class="c-box--tell__time">（平日9:00～18:00）</p>
  </div>
  <div class="c-box--tell__fax">
    <p class="c-box--tell__ttl">フリーコールFAX</p>
    <p class="c-box--tell__number">0120-451-802</p>
    <p class="c-box--tell__time">（24時間受信）</p>
  </div>
</div>
```

*/
.c-box--tell {
  width: 100%;
  margin: 0 auto;
  display: block;
  background-color: #FAFAFA;
  border: 3px solid #DDDDDD;
  padding: 20px 0;
}

.c-box--tell:after {
  content: '';
  display: block;
  clear: both;
}

@media (min-width: 769px) {
  .c-box--tell {
    width: 580px;
    padding: 20px 60px;
  }
}

.c-box--tell__tel {
  margin: 0 auto 25px;
  width: 200px;
}

@media (min-width: 769px) {
  .c-box--tell__tel {
    width: auto;
    float: left;
    margin: 0;
  }
}

.c-box--tell__fax {
  width: 200px;
  margin: 0 auto;
}

@media (min-width: 769px) {
  .c-box--tell__fax {
    width: auto;
    float: right;
  }
}

.c-box--tell__ttl {
  font-size: 1.6rem;
  margin: 0 0 5px;
}

.c-box--tell__number {
  display: block;
  font-weight: bold;
  font-size: 2.4rem;
  margin: 0 0 10px;
}

.c-box--tell__number:hover {
  text-decoration: none;
}

.c-box--tell__time {
  font-size: 1.6rem;
}

/*doc
---
title: 見出し（h2）
name: 見出し（h2）
category: heading
---

## h2相当の見出しに使用するスタイルです。基本的にページの上部、ぱんくずのすぐ下に使われます。

```html_example
<h2 class="c-heading2">
  <span class="c-heading2__inner">品質管理</span>
</h2>
```

*/
.c-heading2 {
  width: 100%;
  background-color: #7DA1CA;
  color: #fff;
  line-height: 1.4;
  padding: 15px 10px;
  margin: 0 auto 45px;
}

@media (min-width: 769px) {
  .c-heading2 {
    margin: 0 auto 90px;
    padding: 30px 0;
  }
}

.c-heading2__inner {
  display: block;
  max-width: 960px;
  margin: 0 auto;
  font-size: 2rem;
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
}

@media (min-width: 769px) {
  .c-heading2__inner {
    font-size: 3.8rem;
    margin: 0 auto;
  }
}

/*doc
---
title: 見出し（h3）
name: 見出し（h3）
category: heading
---

## h3相当の見出しに使用するスタイルです。

```html_example
<h3 class="c-heading3">RoHS対応調書報告書</h3>
```

*/
.c-heading3 {
  width: 100%;
  color: #344796;
  font-size: 1.8rem;
  line-height: 1.2;
  border-bottom: 3px solid #3A4299;
  padding: 0 0 5px;
  margin: 0 auto 20px;
}

@media (min-width: 769px) {
  .c-heading3 {
    font-size: 2.8rem;
    margin: 0 auto 40px;
    padding: 0 0 10px;
  }
}

/*doc
---
title: 見出し（h4）
name: 見出し（h4）
category: heading
---

## h4相当の見出しに使用するスタイルです。

```html_example
<h4 class="c-heading4">ホースグリップ</h4>
```

*/
.c-heading4, .c-card__ttl {
  width: 100%;
  color: #333333;
  font-size: 1.6rem;
  line-height: 1.2;
  border-bottom: 1px solid #CCCCCC;
  padding: 0 0 5px;
  margin: 0 auto 7px;
}

@media (min-width: 769px) {
  .c-heading4, .c-card__ttl {
    font-size: 1.8rem;
    margin: 0 auto 15px;
    padding: 0 0 10px;
  }
}

/*doc
---
title: 見出し（h5）
name: 見出し（h5）
category: heading
---

## h5相当の見出しに使用するスタイルです。

```html_example
<h5 class="c-heading5">JCS製品</h5>
```

*/
.c-heading5 {
  width: 100%;
  color: #323232;
  font-size: 1.4rem;
  line-height: 1.2;
  margin: 0 auto 5px;
  padding: 0 0 0 10px;
  border-left: 6px solid #4B4B4B;
}

@media (min-width: 769px) {
  .c-heading5 {
    font-size: 1.6rem;
    margin: 0 auto 10px;
  }
}

/*doc
---
title: 1.ボタン（デフォルト）
name: 1.ボタン（デフォルト）
category: btn
---

## ボタン

```html_example
<a class="c-btn" href="#">デフォルトのボタン</a>
```

*/
.c-btn, .c-searchBox__submit input {
  display: block;
  text-align: center;
  border-radius: 4px;
  background-color: #fff;
  padding: 20px 0 17px;
  box-shadow: 3px 3px 0 0 #dddddd;
  font-size: 1.6rem;
  transition: 0.5s;
}

.c-btn:hover, .c-searchBox__submit input:hover {
  text-decoration: none;
  opacity: 0.8;
}

/*doc
---
title: 2.ボタン（オレンジ）
name: 2.ボタン（オレンジ）
category: btn
---

## 下部のお問い合わせに使っているボタン

```html_example
<a class="c-btn c-btn--orange" href="#">お問い合わせ</a>
```

*/
.c-btn--orange {
  color: #fff;
  background-color: #F74700;
  box-shadow: 3px 3px 0 0 #c33700;
}

/*doc
---
title: 3.ボタン（ブルー）
name: 3.ボタン（ブルー）
category: btn
---

## 青いボタン

```html_example
<a class="c-btn c-btn--blue" href="#">お問い合わせ</a>
```

*/
.c-btn--blue {
  color: #fff;
  background-color: #79A3C8;
  box-shadow: 3px 3px 0 0 #657c97;
}

/*doc
---
title: 4.ボタン（フラット）
name: 4.ボタン（フラット）
category: btn
---

## 青いボタン・影なし

```html_example
<a class="c-btn c-btn--flat" href="#">お問い合わせ</a>
```

*/
.c-btn--flat, .c-searchBox__submit input {
  color: #fff;
  background-color: #3A4299;
  box-shadow: none;
}

/*doc
---
title: 5.ボタン（別窓）
name: 5.ボタン（別窓）
category: btn
---

## 青いボタン・影なし

```html_example
<a class="c-btn c-btn--blank" href="#">お問い合わせ</a>
```

*/
.c-btn--blank {
  border: 1px solid #DDDDDD;
  color: #fff;
  background-color: #3A4299;
  box-shadow: none;
  background-image: url("../images/common/icon_blank-white.png");
  background-repeat: no-repeat;
  background-position: 95% center;
  background-position: right 15px center;
  background-size: 15px auto;
}

/*doc
---
title: 6.ボタン（PDF）
name: 6.ボタン（PDF）
category: btn
---

## 青いボタン・影なし

```html_example
<a class="c-btn c-btn--pdf" href="#">お問い合わせ</a>
```

*/
.c-btn--pdf {
  border: 1px solid #DDDDDD;
  color: #333;
  box-shadow: none;
  background-image: url("../images/common/icon_pdf.png");
  background-repeat: no-repeat;
  background-position: 95% center;
  background-position: right 15px center;
  background-size: 20px auto;
}

/*doc
---
title: 7.ボタン（固定幅）
name: 7.ボタン（固定幅）
category: btn
---

## クラスをつけると固定幅にできる。（クラスなしは100%）
- .is-small で 250px に。
- .is-middle で 500px に。
- .is-wide で 700px に。
- .is-center で中央に寄せに。

```html_example
<a class="c-btn c-btn--blue is-small" href="#">お問い合わせ</a>
<hr>
<a class="c-btn c-btn--orange is-middle" href="#">お問い合わせ</a>
<hr>
<a class="c-btn is-wide" href="#">お問い合わせ</a>
<hr>
<a class="c-btn c-btn--flat is-fix is-center" href="#">お問い合わせ</a>
```

*/
@media (min-width: 769px) {
  .c-btn.is-small, .c-searchBox__submit input.is-small {
    width: 300px;
  }
}

@media (min-width: 769px) {
  .c-btn.is-middle, .c-searchBox__submit input.is-middle {
    width: 500px;
  }
}

@media (min-width: 769px) {
  .c-btn.is-wide, .c-searchBox__submit input.is-wide {
    width: 700px;
  }
}

.c-btn.is-center, .c-searchBox__submit input.is-center {
  margin: 0 auto;
}

.c-btn.is-disabled, .c-searchBox__submit input.is-disabled {
  background-color: #bbb;
  opacity: 0.9;
}

/*doc
---
title: 1.リスト
name: 1.リスト
category: list
---

## 普通のリスト

```html_example
<ul class="c-list">
  <li>リスト</li>
  <li>リスト</li>
  <li>リスト</li>
</ul>
```

*/
ul.c-list li {
  line-height: 1.3;
  position: relative;
  padding: 0 0 0 1em;
  margin: 0 0 5px;
  font-size: 1.4rem;
}

ul.c-list li:before {
  content: '・';
  display: block;
  width: 1em;
  position: absolute;
  top: 0;
  left: 0;
}

/*doc
---
title: 2.リスト
name: 2.リスト
category: list
---

## 普通のリスト

```html_example
<ol class="c-list">
  <li>リスト</li>
  <li>リスト</li>
  <li>リスト</li>
</ol>
```

*/
ol.c-list li {
  font-size: 1.4rem;
  line-height: 1.3;
  position: relative;
  margin: 0 0 5px 1.5em;
  list-style-type: decimal;
}

/*doc
---
title: 3.リスト
name: 3.リスト
category: list
---

## FAQのリスト

```html_example
<dl class="c-list--faq">
  <dt>質問が入ります</dt>
  <dd>回答が入ります</dd>
</dl>
```

*/
.c-list--faq {
  padding: 10px 0;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  margin: 0 0 5px;
}

@media (min-width: 769px) {
  .c-list--faq {
    padding: 20px 0;
    margin: 0 0 10px;
  }
}

.c-list--faq dt {
  min-height: 20px;
  padding: 0 0 0 25px;
  margin: 0 0 10px;
  background-image: url("../images/common/icon_faq-q.png");
  background-repeat: no-repeat;
  background-position: left 2px;
  background-size: 20px;
}

@media (min-width: 769px) {
  .c-list--faq dt {
    background-position: left top;
    min-height: 40px;
    padding: 6px 0 0 50px;
    margin: 0 0 20px;
    background-size: 40px;
  }
}

.c-list--faq dd {
  min-height: 20px;
  padding: 0 0 0 25px;
  background-image: url("../images/common/icon_faq-a.png");
  background-repeat: no-repeat;
  background-position: left 2px;
  background-size: 20px;
}

@media (min-width: 769px) {
  .c-list--faq dd {
    background-position: left top;
    min-height: 40px;
    padding: 8px 0 0 50px;
    background-size: 40px;
    margin: 0;
  }
}

/*doc
---
title: 1.画像＋テキスト（縦）
name: 1.画像＋テキスト（縦）
category: thumb
---

## 画像とその下にテキストがはいるもの。

```html_example
<div class="c-thumb">
  <div class="c-thumb__img">
    <img src="http://placehold.jp/300x300.png">
  </div>
  <p class="c-thumb__txt">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
</div>
```

*/
.c-thumb__img img {
  display: block;
  width: 100%;
}

.c-thumb__txt {
  padding: 10px 10px 15px;
  font-size: 1.2rem;
  line-height: 1.4;
}

@media (min-width: 769px) {
  .c-thumb__txt {
    padding: 15px;
    font-size: 1.4rem;
    line-height: 1.6;
  }
}

/*doc
---
title: 2.画像＋テキスト（横）
name: 2.画像＋テキスト（横）
category: thumb
---

## 画像とテキストが横並びのもの。

```html_example
<div class="c-thumb--it">
  <div class="c-thumb--it__img">
    <img src="http://placehold.jp/300x300.png">
  </div>
  <p class="c-thumb--it__txt">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
</div>
```

*/
.c-thumb--it:after {
  content: '';
  display: block;
  clear: both;
}

.c-thumb--it__img {
  width: 30%;
  float: left;
}

.c-thumb--it__txt {
  float: right;
  width: 70%;
  padding: 0 0 0 10px;
  font-size: 1.4rem;
  line-height: 1.6;
}

@media (min-width: 769px) {
  .c-thumb--it__txt {
    padding: 0 0 0 15px;
  }
}

/*doc
---
title: 3.テキスト＋画像（横）
name: 3.テキスト＋画像（横）
category: thumb
---

## 画像とテキストが横並びのもの。

```html_example
<div class="c-thumb--ti">
  <div class="c-thumb--ti__img">
    <img src="http://placehold.jp/300x300.png">
  </div>
  <p class="c-thumb--ti__txt">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
</div>
```

*/
.c-thumb--ti:after {
  content: '';
  display: block;
  clear: both;
}

.c-thumb--ti__img {
  width: 50%;
  float: right;
}

.c-thumb--ti__txt {
  float: left;
  width: 50%;
  padding: 0 15px 0 0;
  font-size: 1.4rem;
  line-height: 1.6;
}

@media (min-width: 769px) {
  .c-thumb--ti__txt {
    padding: 0 30px 0 0;
  }
}

.c-thumb--ti.is-wraparound:after {
  content: '';
  display: block;
  clear: both;
}

.c-thumb--ti.is-wraparound .c-thumb--ti__img {
  width: 50%;
  float: right;
  margin: 0 0 15px 15px;
}

.c-thumb--ti.is-wraparound .c-thumb--ti__txt {
  float: none;
  width: auto;
  padding: 0 15px 0 0;
  font-size: 1.4rem;
  line-height: 1.6;
}

@media (min-width: 769px) {
  .c-thumb--ti.is-wraparound .c-thumb--ti__txt {
    padding: 0 30px 0 0;
  }
}

/*doc
---
title: 1.テーブル
name: 1.テーブル
category: table
---

## テーブル（たて）

```html_example
<div class="c-table c-table--col">
  <table>
    <tr>
      <th>見出し</th>
      <th>見出し</th>
    </tr>
    <tr>
      <td>内容</td>
      <td>内容</td>
    </tr>
  </table>
</div>
```

*/
.c-table {
  width: 100%;
  line-height: 1.4;
}

.c-table table {
  width: 100%;
  border: 1px solid #CCCCCC;
  vertical-align: top;
}

.c-table th {
  color: #fff;
  background-color: #666666;
  padding: 15px 20px;
  vertical-align: top;
}

.c-table td {
  padding: 15px 20px;
  border-bottom: 1px solid #CCCCCC;
  vertical-align: top;
}

.c-table--col tr:first-child th:first-child {
  border-left: none;
}

.c-table--col tr:first-child th:last-child {
  border-right: none;
}

.c-table--col th {
  border-left: 1px solid #CCCCCC;
  border-right: 1px solid #CCCCCC;
  text-align: center;
  vertical-align: top;
}

.c-table--col td {
  border: 1px solid #CCCCCC;
  vertical-align: top;
}

/*doc
---
title: 2.テーブル
name: 2.テーブル
category: table
---

## テーブル（よこ）

```html_example
<div class="c-table c-table--row">
  <table>
    <tr>
      <th>見出し</th>
      <td>内容</td>
    </tr>
    <tr>
      <th>見出し</th>
      <td>内容</td>
    </tr>
  </table>
</div>
```

*/
.c-table--row tr:last-child th {
  border-bottom: none;
}

.c-table--row tr:last-child td {
  border-bottom: none;
}

.c-table--row th {
  text-align: left;
  border: 1px solid #CCCCCC;
  border-bottom: 1px solid #fff;
  vertical-align: top;
}

.c-table--row td {
  border: none;
  border-left: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  vertical-align: top;
}

/*doc
---
title: 3.テーブル（商品詳細）
name: 3.テーブル（商品詳細）
category: table
---

## 商品詳細ページのみに使うテーブル。指定の列が固定

```html_example
<div class="c-table c-table--detail">
  <table>
    <tr>
      <th>商品コード</th>
      <th>型式</th>
      <th>ネジ</th>
      <th>商品選定項目_1</th>
      <th>商品選定項目_2</th>
      <th>商品選定項目_3</th>
      <th>商品選定項目_4</th>
      <th class="is-fix">CAD図面</th>
    </tr>
    <tr>
      <td>04101101</td>
      <td>VSO-01</td>
      <td>1/8</td>
      <td>商品選定項目_1</td>
      <td>商品選定項目_2</td>
      <td>商品選定項目_3</td>
      <td>商品選定項目_4</td>
      <td class="is-fix">ダウンロード</td>
    </tr>
    <tr>
      <td>04101101</td>
      <td>VSO-01</td>
      <td>1/8</td>
      <td>商品選定項目_1</td>
      <td>商品選定項目_2</td>
      <td>商品選定項目_3</td>
      <td>商品選定項目_4</td>
      <td class="is-fix">ダウンロード</td>
    </tr>
  </table>
</div>

```



*/
.c-table--detail {
  display: none;
}

.c-detailTable__wrap {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-left: 100px;
}

.c-detailTable__wrap.has-right .c-detailTable__inner {
  position: relative;
  padding-right: 75px;
}

@media (min-width: 769px) {
  .c-detailTable__wrap {
    overflow: scroll;
    overflow-y: hidden;
    overflow-x: hidden;
    padding-left: 320px;
  }
  .c-detailTable__wrap.is-narrow {
    box-sizing: content-box;
  }
  .c-detailTable__wrap.has-right {
    padding-right: 160px;
  }
  .c-detailTable__wrap.has-right .c-detailTable__inner {
    position: static;
    padding-right: 0;
  }
}

.c-detailTable__inner {
  width: 100%;
  overflow: scroll;
  overflow-y: hidden;
}

@media (min-width: 769px) {
  .c-detailTable__inner {
    width: auto;
    position: static;
    overflow: auto;
  }
}

.c-detailTable__inner2 {
  position: relative;
}

@media (min-width: 769px) {
  .c-detailTable__inner2 {
    position: static;
  }
}

.c-detailTable__left {
  width: 150px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.c-detailTable__left table {
  width: 100px;
  border: 1px solid #ccc;
  border-collapse: collapse;
}

@media (min-width: 769px) {
  .c-detailTable__left table {
    width: 320px;
  }
}

.c-detailTable__left th {
  width: 50px;
  color: #fff;
  background-color: #666666;
  padding: 15px 0;
  border: 1px solid #CCCCCC;
  vertical-align: middle;
  box-sizing: border-box;
  line-height: 1.3;
  font-size: 1.2rem;
}

@media (min-width: 769px) {
  .c-detailTable__left th {
    width: 160px;
    font-size: 1.4rem;
  }
}

.c-detailTable__left td {
  width: 50px;
  padding: 15px 5px;
  background-color: #fff;
  border: 1px solid #CCCCCC;
  word-break: break-all;
  line-height: 1.3;
  font-size: 1.2rem;
}

@media (min-width: 769px) {
  .c-detailTable__left td {
    width: 160px;
    padding: 15px 20px;
    font-size: 1.4rem;
  }
}

@media (min-width: 769px) {
  .c-detailTable__main {
    width: 100%;
    overflow: scroll;
    overflow-y: hidden;
    float: none;
  }
}

.c-detailTable__main table {
  margin: 0 auto;
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.c-detailTable__main th {
  width: 100px;
  color: #fff;
  background-color: #666666;
  padding: 15px 0;
  border: 1px solid #CCCCCC;
  vertical-align: middle;
  line-height: 1.3;
  font-size: 1.2rem;
}

@media (min-width: 769px) {
  .c-detailTable__main th {
    width: 160px;
    font-size: 1.4rem;
  }
}

.c-detailTable__main td {
  width: 100px;
  padding: 15px 15px;
  border: 1px solid #CCCCCC;
  line-height: 1.3;
  font-size: 1.2rem;
}

@media (min-width: 769px) {
  .c-detailTable__main td {
    width: 160px;
    padding: 15px 20px;
    font-size: 1.4rem;
  }
}

.c-detailTable__right {
  position: absolute;
  top: 0;
  right: 0;
}

@media (min-width: 769px) {
  .c-detailTable__right {
    right: 1px;
    border-right: 1px solid #CCCCCC;
  }
}

.c-detailTable__right table {
  border: 1px solid #ccc;
  border-collapse: collapse;
}

@media (min-width: 769px) {
  .c-detailTable__right table {
    border-right: none;
  }
}

.c-detailTable__right th {
  width: 100px;
  color: #fff;
  background-color: #666666;
  padding: 15px 0;
  border: 1px solid #CCCCCC;
  vertical-align: middle;
  line-height: 1.3;
  font-size: 1.2rem;
}

@media (min-width: 769px) {
  .c-detailTable__right th {
    width: 160px;
    font-size: 1.4rem;
    border-right: none;
  }
}

.c-detailTable__right td {
  width: 100px;
  padding: 15px 5px;
  border: 1px solid #CCCCCC;
  line-height: 1.3;
  font-size: 1.2rem;
  vertical-align: middle;
}

@media (min-width: 769px) {
  .c-detailTable__right td {
    width: 160px;
    padding: 15px 20px;
    font-size: 1.4rem;
    border-right: none;
  }
}

table .is-middle {
  vertical-align: middle;
}

/*doc
---
title: 1.テーブルモック
name: 1.テーブルモック
category: table-moc1
---

## プラグイン使用。横幅の可変がうまくいかないので全パターンクラスを用意しておく必要がありそう。

```html_example
<div class="c-table">
  <table id="tablefix">
    <thead>
      <tr>
        <th>商品コード</th>
        <th>型式</th>
        <th>ネジ</th>
        <th>商品選定項目_1</th>
        <th>商品選定項目_2</th>
        <th>商品選定項目_3</th>
        <th>商品選定項目_4</th>
        <th>商品選定項目_5</th>
        <th>商品選定項目_6</th>
        <th>CAD図面</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>04101101</td>
        <td>VSO-01</td>
        <td>1/8</td>
        <td>商品選定項目_1</td>
        <td>商品選定項目_2</td>
        <td>商品選定項目_3</td>
        <td>商品選定項目_4</td>
        <td>商品選定項目_5</td>
        <td>商品選定項目_1</td>
        <td>ダウンロード</td>
      </tr>
      <tr>
        <td>04101101</td>
        <td>VSO-01</td>
        <td>1/8</td>
        <td>商品選定項目_1</td>
        <td>商品選定項目_2</td>
        <td>商品選定項目_2</td>
        <td>商品選定項目_2</td>
        <td>商品選定項目_2</td>
        <td>商品選定項目_1</td>
        <td>ダウンロード</td>
      </tr>
    </tbody>
  </table>
</div>


```

*/
#tablefix {
  width: 1600px;
}

#tablefix th, #tablefix td {
  width: 160px;
  border: 1px solid #CCCCCC;
}

/*doc
---
title: 1.リンク（デフォルト）
name: 1.リンク（デフォルト）
category: link
---

## ボタン

```html_example
<a class="c-link" href="#">
  <span>リンク</span>
</a>
```

*/
.c-link {
  display: block;
  text-decoration: none;
  background-color: #F2FAFF;
  border: 1px solid #3A4199;
  border-radius: 4px;
  color: #344796;
  text-align: left;
  padding: 15px;
}

.c-link span {
  display: block;
  background-image: url("../images/common/icon_arrow_right4.png");
  background-repeat: no-repeat;
  background-position: left 3px;
  background-size: 6px 10px;
  padding: 0 0 0 10px;
}

/*doc
---
title: 2.リンク（デフォルト）
name: 2.リンク（デフォルト）
category: link
---

## ボタン

```html_example
<a class="c-link--page" href="#">リンク</a>
```

*/
.c-link--page {
  display: inline-block;
  text-decoration: none;
  color: #344796;
  background-image: url("../images/common/icon_arrow_right4.png");
  background-repeat: no-repeat;
  background-position: left 3px;
  background-size: 6px 10px;
  padding: 0 0 0 10px;
}

.c-link--page:hover {
  text-decoration: underline;
}

/*doc
---
title: 3.リンク（別窓アイコン）
name: 3.リンク（別窓アイコン）
category: link
---

## 別窓リンク（ボタンは別でパーツ作成しています）

```html_example
<a class="c-link--blank" href="#">リンク</a>
```

*/
.c-link--blank {
  background-image: url("../images/common/icon_blank.png");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 15px auto;
  padding-right: 20px;
}

/*doc
---
title: 4.リンク（PDF）
name: 4.リンク（PDF）
category: link
---

## PDFのリンク（ボタンは別でパーツ作成しています）

```html_example
<a class="c-link--pdf" href="#">リンク</a>
```

*/
.c-link--pdf {
  display: inline-block;
  background-image: url("../images/common/icon_pdf.png");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20px auto;
  padding: 0 25px 0 0;
}

/*doc
---
title: 1.テキスト
name: 1.テキスト
category: txt
---

## テキストです。

```html_example
<p class="c-txt--l">Lサイズのテキストです。余白込みです。</p>
<p class="c-txt--m">Mサイズのテキストです。余白込みです。</p>
<p class="c-txt--s">Sサイズのテキストです。余白込みです。</p>

<p class="c-txt--m is-center">中央寄せ</p>
<p class="c-txt--m is-right">右寄せ</p>
<p class="c-txt--m is-noMargin">マージン無し</p>
<p class="c-txt--m u-fontweight--bold">太字のテキスト</p>
<p class="c-txt--m u-fontcolor--red">赤い文字</p>
<p class="c-txt--l u-letterspacing--m1">文字間詰める</p>


```

*/
.c-txt--l {
  font-size: 1.6rem;
  line-height: 1.6;
  margin: 0 0 15px;
}

@media (min-width: 769px) {
  .c-txt--l {
    font-size: 1.6rem;
    margin: 0 0 30px;
  }
}

.c-txt--m {
  font-size: 1.4rem;
  line-height: 1.6;
  margin: 0 0 10px;
}

@media (min-width: 769px) {
  .c-txt--m {
    font-size: 1.4rem;
    margin: 0 0 20px;
  }
}

.c-txt--s {
  font-size: 1.2rem;
  line-height: 1.6;
  margin: 0 0 5px;
}

@media (min-width: 769px) {
  .c-txt--s {
    font-size: 1.2rem;
    margin: 0 0 10px;
  }
}

.c-txt--l.is-noMargin,
.c-txt--m.is-noMargin,
.c-txt--s.is-noMargin {
  margin: 0;
}

.c-txt--l.is-center,
.c-txt--m.is-center,
.c-txt--s.is-center {
  text-align: center;
}

.c-txt--l.is-left,
.c-txt--m.is-left,
.c-txt--s.is-left {
  text-align: left;
}

.c-txt--l.is-right,
.c-txt--m.is-right,
.c-txt--s.is-right {
  text-align: right;
}

.c-pager {
  padding: 40px 0 0;
}

.c-pager ul {
  text-align: center;
}

.c-pager ul li {
  display: inline-block;
  margin: 0 3px;
}

.c-pager ul li a {
  display: block;
  text-align: center;
  width: 20px;
  line-height: 20px;
  background-color: #F2FAFF;
  border: 1px solid #3A4199;
}

.c-pager ul li a:hover {
  text-decoration: none;
  background-color: #344796;
  color: #ffffff;
}

.c-pager ul li a.is-current {
  background-color: #344796;
  color: #ffffff;
}

.c-news__item {
  border-bottom: 1px dotted #DDDDDD;
  margin: 0 0 20px;
}

.c-news__item a {
  display: block;
  text-decoration: none;
}

.c-news__item a:hover .c-news__text {
  text-decoration: underline;
}

.c-news__date {
  color: #333333;
  font-weight: bold;
  font-size: 1;
  margin: 0 10px 0 0;
}

@media (min-width: 769px) {
  .c-news__date {
    font-size: 1.4rem;
    margin: 0 15px 0 0;
  }
}

.c-news__category {
  display: inline-block;
  vertical-align: baseline;
  width: 110px;
  padding: 4px 0 2px;
  text-align: center;
  border: 1px solid #000;
  font-size: 1rem;
}

@media (min-width: 769px) {
  .c-news__category {
    width: 130px;
    font-size: 1.3rem;
  }
}

.c-news__category.is-blue {
  color: #00A3D9;
  border-color: #00A3D9;
}

.c-news__category.is-green {
  color: #59B200;
  border-color: #59B200;
}

.c-news__category.is-orange {
  color: #FF2209;
  border-color: #FF2209;
}

.c-news__category.is-navy {
  color: #344796;
  border-color: #344796;
}

.c-news__category.is-gray {
  color: #666666;
  border-color: #666666;
}

.c-news__text {
  font-size: 1.4rem;
  line-height: 1.6;
  padding: 10px 0;
  background-image: url("../images/common/icon_arrow_right.png");
  background-repeat: no-repeat;
  background-position: left 17px;
  background-size: 5px 8px;
  padding: 10px 0 15px 13px;
}

@media (min-width: 769px) {
  .c-news__text {
    font-size: 1.6rem;
  }
}

/*doc
---
title: 1.タイトル＋画像＋テキスト＋ボタンのセット
name: 1.タイトル＋画像＋テキスト＋ボタンのセット
category: card
---

## セットのやつ

```html_example
<div class="c-card">
  <p class="c-card__ttl">タイトルが入ります。</p>
  <div class="c-card__img">
    <img src="http://placehold.jp/300x160.png">
  </div>
  <p class="c-card__txt">ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。</p>
  <a href="#" class="c-btn c-btn--flat c-card__btn">詳しくはこちら</a>
</div>
```

*/
.c-card {
  display: block;
  border: 1px solid #C8C8C8;
  padding: 15px;
}

.c-card:after {
  content: '';
  display: block;
  clear: both;
}

.c-card__ttl {
  font-size: 1.8rem;
}

.c-card__img {
  width: 35%;
  margin: 0 0 15px;
  float: right;
}

.c-card__txt {
  width: 60%;
  margin: 0 0 15px;
  float: left;
  line-height: 1.6;
}

.c-card__btn {
  clear: both;
  margin: 0 auto;
}

.c-modal__movie {
  width: 100%;
  position: relative;
  padding: 56.23% 0 0;
}

.c-modal__movie iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (min-width: 769px) {
  .c-modal__movie {
    width: 560px;
    margin: 0 auto;
    padding: 56.25% 0 0;
  }
}

.mfp-container {
  padding: 60px 10px 0;
}

.white-popup {
  position: relative;
  background: #FFF;
  padding: 30px 20px;
  width: auto;
  width: 90%;
  max-width: 800px;
  margin: 20px auto;
}

.c-contact {
  padding: 40px 0 50px;
}

@media (min-width: 769px) {
  .c-contact {
    padding: 80px 0 100px;
  }
}

.c-contact__txt {
  color: #333333;
  font-size: 1.2rem;
  text-align: center;
  margin: 0 auto 15px;
}

@media (min-width: 769px) {
  .c-contact__txt {
    font-size: 2.4rem;
    margin: 0 auto 30px;
  }
}

.c-contact__btn {
  font-size: 1.2rem;
  line-height: 40px;
  padding: 0;
}

@media (min-width: 769px) {
  .c-contact__btn {
    width: 700px;
    margin: 0 auto;
    font-size: 2.4rem;
    padding: 0;
    line-height: 80px;
  }
}

.c-pageTitle {
  margin: 0 auto 40px;
}

@media (min-width: 769px) {
  .c-pageTitle {
    margin: 0 auto 80px;
  }
}

.c-pageTitle__lead {
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
  font-size: 2rem;
  text-align: center;
  color: #323232;
  margin: 0 auto 15px;
  line-height: 1.4;
  text-align: left;
}

.c-pageTitle__lead br {
  display: none;
}

@media (min-width: 769px) {
  .c-pageTitle__lead {
    font-size: 4rem;
    margin: 0 auto 30px;
    text-align: center;
  }
  .c-pageTitle__lead br {
    display: block;
  }
}

.c-pageTitle__text {
  color: #323232;
  font-size: 1.2rem;
  line-height: 1.6;
  text-align: left;
  margin: 15px 0 15px;
}

.c-pageTitle__text br {
  display: none;
}

@media (min-width: 769px) {
  .c-pageTitle__text {
    font-size: 1.6rem;
    margin: 30px 0 30px;
    text-align: center;
  }
  .c-pageTitle__text br {
    display: block;
  }
}

.c-form__mail {
  padding: 5px 10px;
  border: 1px solid #CCCCCC;
  border-radius: 2px;
  font-size: 1.6rem;
}

.c-form__btn {
  width: 100%;
  transition: 0s;
}

.c-sliderWrap {
  margin: 0 -20px;
  position: relative;
  padding: 0 10px 30px;
}

@media (min-width: 769px) {
  .c-sliderWrap {
    padding: 0 0 30px;
    margin: 0;
  }
}

.c-slider {
  position: relative;
}

.c-slider__item {
  position: relative;
  width: 200px;
}

@media (min-width: 769px) {
  .c-slider__item {
    width: 500px;
  }
}

.c-slider__item.slick-current .c-slider__itemInner {
  background-color: transparent;
}

.c-slider__itemInner {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.c-slider .slick-arrow {
  background-color: transparent;
  border: none;
  font-size: 0;
  position: absolute;
  top: 50%;
  z-index: 101;
  width: 23px;
  height: 43px;
  margin-top: -13px;
  outline: none;
}

.c-slider .slick-prev {
  display: none;
}

@media (min-width: 769px) {
  .c-slider .slick-prev {
    background-image: url("../../images/common/slide_prev.png");
    left: 20%;
  }
}

.c-slider .slick-next {
  display: none;
}

@media (min-width: 769px) {
  .c-slider .slick-next {
    background-image: url("../../images/common/slide_next.png");
    right: 20%;
  }
}

.c-slider .slick-dots {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 0 0 10px;
}

.c-slider .slick-dots li {
  display: inline-block;
  margin: 0 5px;
}

.c-slider .slick-dots button {
  display: block;
  padding: 0;
  width: 11px;
  height: 11px;
  background: none;
  border: none;
  font-size: 0;
  background-color: #EDEFF1;
  border-radius: 100%;
  outline: none;
}

.c-slider .slick-dots .slick-active button {
  background-color: #333333;
}

.c-searchBox {
  background-color: #FAFAFA;
  border: 1px solid #DDDDDD;
  padding: 15px;
  margin: 0 auto 15px;
}

@media (min-width: 769px) {
  .c-searchBox__selects {
    width: 100%;
    display: table;
    margin: 0 -10px 15px;
  }
}

.c-searchBox__select {
  margin: 0 0 10px;
}

@media (min-width: 769px) {
  .c-searchBox__select {
    display: table-cell;
    width: 25%;
    padding: 0 10px;
    margin: 0;
  }
}

.c-searchBox__select select {
  display: block;
  width: 100%;
  height: 1.6em;
  background: none;
  border: 1px solid #DDDDDD;
  border-radius: 0;
  background-color: #FFFFFF;
  font-size: 16px;
  font-family: "Hiragino Kaku Gothic ProN", "游ゴシック", "メイリオ", meiryo, sans-serif;
}

.c-searchBox__select option {
  font-family: "Hiragino Kaku Gothic ProN", "游ゴシック", "メイリオ", meiryo, sans-serif;
}

@media (min-width: 769px) {
  .c-searchBox__bottom {
    width: 100%;
    display: table;
    margin: 0 -10px;
  }
}

.c-searchBox__radio {
  font-size: 1.4rem;
  margin: 0 0 10px;
}

@media (min-width: 769px) {
  .c-searchBox__radio {
    display: table-cell;
    padding: 0 10px;
    width: 30%;
    margin: 0;
    vertical-align: middle;
  }
}

.c-searchBox__radio span {
  display: inline-block;
  border-right: 1px solid #DDDDDD;
  padding: 10px 20px 10px 10px;
}

.c-searchBox__radio label {
  display: inline-block;
  padding: 10px;
  font-size: 1.4rem;
}

.c-searchBox__radio input {
  display: inline-block;
}

.c-searchBox__text {
  margin: 0 0 10px;
}

@media (min-width: 769px) {
  .c-searchBox__text {
    display: table-cell;
    padding: 0 10px;
    width: 35%;
    margin: 0;
    vertical-align: middle;
  }
}

.c-searchBox__text input {
  display: block;
  width: 100%;
  padding: 6px 10px;
  font-size: 1.6rem;
  border: 1px solid #DDDDDD;
}

@media (min-width: 769px) {
  .c-searchBox__submit {
    display: table-cell;
    padding: 0 10px;
    width: 35%;
    vertical-align: middle;
  }
}

.c-searchBox__submit input {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 1.4rem;
}

.p-com__table th {
  width: 100px;
}

@media (min-width: 769px) {
  .p-com__table th {
    width: 150px;
  }
}

.p-com__map {
  width: 100%;
  position: relative;
  padding: 68% 0 0;
  margin: 0 auto 15px;
}

@media (min-width: 769px) {
  .p-com__map {
    margin: 0;
  }
}

.p-com__map iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.p-con input {
  width: 100%;
}

.p-con .c-table tr {
  display: block;
}

@media (min-width: 769px) {
  .p-con .c-table tr {
    display: table-row;
  }
}

.p-con .c-table th {
  display: block;
  width: 100%;
  border: none;
}

@media (min-width: 769px) {
  .p-con .c-table th {
    width: 300px;
    display: table-cell;
    border: 1px solid #CCCCCC;
    border-bottom: 1px solid #fff;
  }
}

.p-con .c-table td {
  display: block;
  width: 100%;
  border: none;
}

@media (min-width: 769px) {
  .p-con .c-table td {
    width: auto;
    display: table-cell;
    border-left: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
  }
}

.p-con .c-table input[type="radio"] {
  display: inline-block;
  width: auto;
  margin: 0 10px 0 5px;
}

.p-con .c-table input[type="text"] {
  display: inline-block;
  width: 100%;
  margin: 0 0 5px;
  padding: 6px 10px;
  font-size: 1.6rem;
  border: 1px solid #DDDDDD;
}

.p-con .c-table input[type="text"].is-number--small {
  display: inline-block;
  width: 20%;
  margin: 0 10px 5px;
}

.p-con .c-table input[type="text"].is-number--wide {
  display: inline-block;
  width: 24%;
  margin: 0 10px 5px;
}

.p-con .c-table select {
  display: block;
  width: 100%;
  height: 1.6em;
  background: none;
  border: 1px solid #DDDDDD;
  border-radius: 0;
  background-color: #FFFFFF;
  font-size: 16px;
  font-family: "Hiragino Kaku Gothic ProN", "游ゴシック", "メイリオ", meiryo, sans-serif;
}

.p-con .c-table option {
  font-family: "Hiragino Kaku Gothic ProN", "游ゴシック", "メイリオ", meiryo, sans-serif;
}

.p-con .c-table textarea {
  font-size: 16px;
  display: block;
  width: 100%;
  min-height: 3em;
}

.p-devFab__imgs:after {
  content: '';
  display: block;
  clear: both;
}

.p-devFab__imgs img {
  width: 47%;
  display: block;
}

.p-devFab__imgs img:nth-of-type(1) {
  float: left;
}

.p-devFab__imgs img:nth-of-type(2) {
  float: right;
}

.p-devFabRoh__table th:first-child {
  width: 50px;
}

@media (min-width: 769px) {
  .p-devFabRoh__table th:first-child {
    width: 150px;
  }
}

.p-devFabRoh__table tr td:last-child {
  width: 80px;
  padding-left: 5px;
  padding-right: 5px;
}

.p-devFabRoh__table tr td:last-child .c-link--pdf {
  background-position: bottom right;
}

@media (min-width: 769px) {
  .p-devFabRoh__table tr td:last-child {
    width: 150px;
  }
  .p-devFabRoh__table tr td:last-child br {
    display: none;
  }
}

.p-devFabRoh__spMargin {
  padding-bottom: 15px;
}

@media (min-width: 769px) {
  .p-devFabRoh__spMargin {
    padding-bottom: 0;
  }
}

.p-serWho__imgs:after {
  content: '';
  display: block;
  clear: both;
}

.p-serWho__imgs .left {
  margin: 0 0 15px;
}

@media (min-width: 769px) {
  .p-serWho__imgs .left {
    width: 65%;
    margin: 0;
    padding: 0 20px 0 0;
    float: left;
  }
}

.p-serWho__imgs .left .c-box--border {
  padding: 30px 10px;
}

.p-serWho__imgs .right:after {
  content: '';
  display: block;
  clear: both;
}

@media (min-width: 769px) {
  .p-serWho__imgs .right {
    width: 35%;
    padding: 0 0 0 20px;
    float: right;
  }
}

.p-serWho__imgs .right img {
  display: block;
  width: 48%;
}

@media (min-width: 769px) {
  .p-serWho__imgs .right img {
    width: 100%;
  }
}

.p-serWho__imgs .right img:first-child {
  float: left;
}

@media (min-width: 769px) {
  .p-serWho__imgs .right img:first-child {
    float: none;
  }
}

.p-serWho__imgs .right img:last-child {
  float: right;
}

@media (min-width: 769px) {
  .p-serWho__imgs .right img:last-child {
    float: none;
  }
}

.p-itemDetail__btns {
  text-align: center;
  margin-left: -20px;
  margin-right: -20px;
  font-size: 0;
}

.p-itemDetail__btns > p {
  width: calc(100% / 3);
  display: inline-block;
  padding: 0 20px;
}

.p-rec__table th {
  width: 100px;
}

@media (min-width: 769px) {
  .p-rec__table th {
    width: 200px;
  }
}

.p-new__main img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.p-str__col:after {
  content: '';
  display: block;
  clear: both;
}

@media (min-width: 769px) {
  .p-str__left {
    width: 48%;
    float: left;
  }
}

@media (min-width: 769px) {
  .p-str__right {
    width: 48%;
    float: right;
  }
}

.p-str .c-pageTitle__lead {
  text-align: left;
}

@media (min-width: 769px) {
  .p-str .c-pageTitle__lead {
    font-size: 3.7rem;
  }
}

.p-str .c-pageTitle__lead span {
  font-size: 80%;
}

@media (min-width: 769px) {
  .p-str .c-pageTitle__lead span {
    font-size: 60%;
  }
}

.p-str .c-pageTitle__text {
  text-align: left;
}

.p-str__fade .c-pageTitle__lead {
  position: relative;
  top: 50px;
  opacity: 0;
  transition: 0.4s all 0s;
}

.p-str__fade .c-pageTitle__text {
  position: relative;
  top: 50px;
  opacity: 0;
  transition: 0.4s all 0.1s;
}

.p-str__fade .p-str__img {
  position: relative;
  top: 50px;
  opacity: 0;
  transition: 0.4s all 0.3s;
}

.p-str__fade.is-show .c-pageTitle__lead,
.p-str__fade.is-show .c-pageTitle__text,
.p-str__fade.is-show .p-str__img {
  top: 0;
  opacity: 1;
}

@media (min-width: 769px) {
  .p-top .c-inner {
    width: 100%;
    padding: 0 30px;
    max-width: 1200px;
    margin: 0 auto;
  }
}

.p-top__ttl {
  font-size: 2.4rem;
  color: #333333;
  font-weight: bold;
  text-align: center;
  position: relative;
  padding: 0 0 20px;
  margin: 0 auto 20px;
}

@media (min-width: 769px) {
  .p-top__ttl {
    font-size: 2.8rem;
    margin: 0 auto 50px;
  }
}

.p-top__ttl:after {
  content: '';
  display: block;
  width: 24px;
  height: 1px;
  background-color: #000000;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -12px;
}

.p-top__block--gray {
  background-color: #F6F6F6;
  padding: 30px 0 20px;
}

@media (min-width: 769px) {
  .p-top__block--gray {
    padding: 60px 0 50px;
  }
}

.p-topSlider {
  margin: 0 0 10px;
}

@media (min-width: 769px) {
  .p-topSlider {
    margin: 0 0 100px;
  }
}

.p-topSlider__item {
  width: 300px;
}

@media (min-width: 769px) {
  .p-topSlider__item {
    width: 750px;
  }
}

.p-top .c-slider__shadow--left {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 50%;
  margin: 0 0 0 150px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

@media (min-width: 769px) {
  .p-top .c-slider__shadow--left {
    width: 50%;
    left: 0;
    margin: 0 0 0 -375px;
  }
}

.p-top .c-slider__shadow--right {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  right: 50%;
  margin: 0 150px 0 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

@media (min-width: 769px) {
  .p-top .c-slider__shadow--right {
    width: 50%;
    right: 0;
    margin: 0 -375px 0 0;
  }
}

.p-top .slick-prev {
  left: 50%;
  margin-left: -420px;
}

.p-top .slick-next {
  right: 50%;
  margin-right: -420px;
}

@media (min-width: 769px) {
  .p-top .c-slider .slick-dots {
    padding: 20px 0 0;
  }
}

.p-top__facebook {
  width: 100%;
}

.p-top__3col {
  border-left: 1px solid #CCCCCC;
  border-right: 1px solid #CCCCCC;
  background-color: #FFFFFF;
  margin: 0 auto 20px;
}

@media (min-width: 769px) {
  .p-top__3col {
    width: 100%;
    display: table;
    border-collapse: collapse;
    margin: 0 auto 50px;
  }
}

.p-top__3colItem {
  padding: 15px 25px;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}

@media (min-width: 769px) {
  .p-top__3colItem {
    display: table-cell;
    border: 1px solid #CCCCCC;
    padding: 50px 50px;
  }
}

.p-top__3colItem .c-btn, .p-top__3colItem .c-searchBox__submit input, .c-searchBox__submit .p-top__3colItem input {
  margin-top: 10px;
}

.p-top__3colImg {
  display: block;
  margin: 0 auto 10px;
}

@media (min-width: 769px) {
  .p-top__3colImg {
    margin: 0 auto 25px;
  }
}

.p-top__3colTtl {
  font-size: 1.6rem;
  font-weight: bold;
  color: #333333;
  text-align: center;
  line-height: 1.6;
  margin: 0 auto 10px;
}

@media (min-width: 769px) {
  .p-top__3colTtl {
    font-size: 1.9rem;
    margin: 0 auto 25px;
  }
}

.p-top__3colTxt {
  font-size: 1.4rem;
  color: #333333;
  line-height: 1.6;
  text-align: center;
}

.p-top-itemInfoBlock {
  display: block;
  border: 1px solid #CCCCCC;
  margin: 0 auto 10px;
}

@media (min-width: 769px) {
  .p-top-itemInfoBlock {
    display: table;
    margin: 0 auto 20px;
  }
}

.p-top-itemInfoBlock:hover {
  text-decoration: none;
}

.p-top-itemInfoBlock__txt {
  padding: 25px 15px 15px;
}

@media (min-width: 769px) {
  .p-top-itemInfoBlock__txt {
    display: table-cell;
    width: 50%;
    text-align: center;
    padding: 0;
    vertical-align: middle;
  }
}

.p-top-itemInfoBlock__txt img {
  display: block;
  width: 73%;
  margin: 0 auto 15px;
}

@media (min-width: 769px) {
  .p-top-itemInfoBlock__txt img {
    width: 50%;
  }
}

.p-top-itemInfoBlock__txt p {
  line-height: 1.6;
}

.p-top-itemInfoBlock__txt p br {
  display: none;
}

@media (min-width: 769px) {
  .p-top-itemInfoBlock__txt p br {
    display: block;
  }
}

@media (min-width: 769px) {
  .p-top-itemInfoBlock__img {
    display: table-cell;
    width: 50%;
  }
}

.p-top-itemInfoBlock__img img {
  display: block;
}

.p-top-itemInfoNavi:after {
  content: '';
  display: block;
  clear: both;
}

.p-top-itemInfoNavi .c-column__item a {
  display: block;
  border: 1px solid #CCCCCC;
}

.p-top-itemInfoNavi__ttl {
  display: block;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlMWUxZTQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, white 0%, #e1e1e4 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, white 0%, #e1e1e4 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, white 0%, #e1e1e4 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  text-align: center;
  font-size: 1.4rem;
  line-height: 60px;
  font-weight: bold;
}

@media (min-width: 769px) {
  .p-top-itemInfoNavi__ttl {
    font-size: 1.6rem;
  }
}

.p-top-itemInfoNavi__img {
  display: block;
}

.p-top__service:after {
  content: '';
  display: block;
  clear: both;
}

.p-top__serviceItem {
  width: 50%;
  float: left;
}

.p-top__serviceItem:nth-of-type(odd) {
  clear: both;
}

@media (min-width: 769px) {
  .p-top__serviceItem {
    width: 22%;
    margin: 0 4% 30px 0;
  }
  .p-top__serviceItem:nth-of-type(odd) {
    clear: none;
  }
  .p-top__serviceItem:nth-of-type(4n) {
    margin: 0 0 30px;
  }
  .p-top__serviceItem:nth-of-type(4n+1) {
    clear: both;
  }
}

.p-top__serviceItem a {
  display: block;
  border: 1px solid #CCCCCC;
  padding: 10px 3px;
}

@media (min-width: 769px) {
  .p-top__serviceItem a {
    padding: 25px;
  }
}

.p-top__serviceItem a span {
  display: block;
  text-align: center;
  font-size: 1.4rem;
  color: #333333;
  line-height: 1.2;
  margin: 0 auto 5px;
}

@media (min-width: 769px) {
  .p-top__serviceItem a span {
    font-size: 1.6rem;
  }
}

@media (min-width: 769px) {
  .p-top__serviceItem:nth-of-type(1) span {
    padding-top: 0.5em;
  }
}

@media (min-width: 769px) {
  .p-top__serviceItem:nth-of-type(2) span {
    padding-top: 0.5em;
  }
}

.p-top__serviceItem:last-child span {
  padding-top: 0.5em;
}

.p-top__bnr .c-column__item {
  margin-bottom: 10px;
}

.p-top__bnr .c-column__item a {
  display: block;
  position: relative;
}

.p-top__bnr .c-column__item span {
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-size: 1.4rem;
  color: #333333;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 100%;
  font-weight: bold;
}

@media (min-width: 769px) {
  .p-top__bnr .c-column__item span {
    width: 130px;
    height: 130px;
    line-height: 130px;
    font-size: 1.6rem;
  }
}

.p-eng__table th {
  width: 100%;
  display: block;
  border: none;
}

@media (min-width: 769px) {
  .p-eng__table th {
    display: table-cell;
    width: 200px;
    border-bottom: 1px solid #CCCCCC;
  }
}

.p-eng__table td {
  width: 100%;
  display: block;
  border: none;
}

@media (min-width: 769px) {
  .p-eng__table td {
    display: table-cell;
    width: auto;
    border-bottom: 1px solid #CCCCCC;
  }
}

.p-chi__table th {
  width: 100%;
  display: block;
  border: none;
}

@media (min-width: 769px) {
  .p-chi__table th {
    display: table-cell;
    width: 150px;
    border-bottom: 1px solid #CCCCCC;
  }
}

.p-chi__table td {
  width: 100%;
  display: block;
  border: none;
}

@media (min-width: 769px) {
  .p-chi__table td {
    display: table-cell;
    width: auto;
    border-bottom: 1px solid #CCCCCC;
  }
}

.p-kor__table th {
  width: 100%;
  display: block;
  border: none;
}

@media (min-width: 769px) {
  .p-kor__table th {
    display: table-cell;
    width: 200px;
    border-bottom: 1px solid #CCCCCC;
  }
}

.p-kor__table td {
  width: 100%;
  display: block;
  border: none;
}

@media (min-width: 769px) {
  .p-kor__table td {
    display: table-cell;
    width: auto;
    border-bottom: 1px solid #CCCCCC;
  }
}

/*doc
---
title: 1.表示非表示
name: 1.表示非表示
category: show
---

## PC/SPで表示の出し分けをするためのクラスです。

```html_example
<p class="u-showSP">このテキストはSP幅のみで表示されます。</p>
<p class="u-showPC">このテキストはPC幅のみで表示されます。</p>
```

*/
.u-showSP {
  display: block;
}

@media (min-width: 769px) {
  .u-showSP {
    display: none;
  }
}

.u-showPC {
  display: none;
}

@media (min-width: 769px) {
  .u-showPC {
    display: block;
  }
}

.u-letterspacing--m1 {
  letter-spacing: -0.1em !important;
}

.u-fontcolor--red {
  color: #ff0000 !important;
}

.u-fontweight--bold {
  font-weight: bold !important;
}
