@charset "UTF-8";

.p-new{

  &__main{

    img{
      width: auto;
      height: auto;
      max-width: 100%;
    }
  }
}


