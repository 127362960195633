@charset "UTF-8";

/*doc
---
title: 1.表示非表示
name: 1.表示非表示
category: show
---

## PC/SPで表示の出し分けをするためのクラスです。

```html_example
<p class="u-showSP">このテキストはSP幅のみで表示されます。</p>
<p class="u-showPC">このテキストはPC幅のみで表示されます。</p>
```

*/


.u-showSP{
  display: block;

  @include breakpoint(pc){
    display: none;
  }
}

.u-showPC{
  display: none;

  @include breakpoint(pc){
    display: block;
  }
}
