@charset "UTF-8";

.p-com{

  &__table{
    th{
      width: 100px;

      @include breakpoint(pc){
        width: 150px;
      }
    }
  }

  &__map{
    width: 100%;
    position: relative;
    padding: 68% 0 0;
    margin: 0 auto 15px;

    @include breakpoint(pc){
      margin: 0;
    }

    iframe{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
    }
  }
}


