@charset "UTF-8";

.p-rec{

  &__table{
    th{
      width: 100px;

      @include breakpoint(pc){
        width: 200px;
      }
    }
  }
}


