@charset "UTF-8";

.c-contact{
  padding: 40px 0 50px;

  @include breakpoint(pc){
    padding: 80px 0 100px;
  }

  &__txt{
    color: #333333;
    font-size: 1.2rem;
    text-align: center;
    margin: 0 auto 15px;

    @include breakpoint(pc){
      font-size: 2.4rem;
      margin: 0 auto 30px;
    }
  }

  &__btn{
    font-size: 1.2rem;
    line-height: 40px;
    padding: 0;

    @include breakpoint(pc){
      width: 700px;
      margin: 0 auto;
      font-size: 2.4rem;
      padding: 0;
      line-height: 80px;
    }
  }
}

