@charset "UTF-8";

.l-breadcrumb{
  padding: 20px 0 8px;
  border-bottom: 2px solid #C8C8C8;
  margin: 0 0 5px;
  line-height: 1.6;

  @include breakpoint(pc){
    padding: 40px 0 15px;
    margin: 0 0 10px;
  }

  ul{
    @include cfx;
  }

  li{
    float: left;
    font-size: 1rem;

    @include breakpoint(pc){
      font-size: 1.2rem;
    }

    &:before{
      content: '＞';
      display: inline-block;
      padding: 0 5px;
    }

    &:first-child{
      &:before{
        display: none;
      }
    }
  }
}

