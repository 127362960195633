@charset "UTF-8";
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, abbr, address, cite, code, del, dfn, em,
img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i,
dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table,
caption, tbody, tfoot, thead, tr, th, td, article, aside,
canvas, details, figcaption, figure,  footer, header, hgroup,
menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}
html, body {
  line-height: 1;
}
main,article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
  display: block;
}
ul,ol {
  list-style: none;
}
li{
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
a {
  margin: 0;
  padding: 0;
  outline: none;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  &:hover{
    text-decoration: none;
  }
}
a,span{
  color: inherit;
  font-weight: inherit;
}
table {
  border-collapse:collapse;
  border-spacing:0;
}
sup {
  vertical-align: super;
}

/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
//  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
    &::-webkit-search-decoration {
      display: none;
    }
    &::focus {
      outline-offset: -2px;
    }
}
