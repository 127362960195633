@charset "UTF-8";

.l-header{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  max-height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  background-color: #fff;

  @include breakpoint(pc){
    position: static;
    overflow: visible;
  }

  &__sp{
    display: block;

    @include breakpoint(pc){
      display: none;
    }
  }

  &__pc{
    display: none;

    @include breakpoint(pc){
      @include cfx;
      display: block;
      float: right;
      position: relative;
      z-index: 2;
    }
  }

  &__blockLeft{
    float: left;
    padding: 0 20px 0 0;
  }

  &__main{
    height: 60px;
    position: relative;
    border-bottom: 1px solid $border-color;

    @include breakpoint(pc){
      height: 80px;
    }
  }

  &__logo{
    font-size: 1rem;
    padding: 20px 0 0 7px;

    @include breakpoint(pc){
      padding: 20px 0 0 30px;
      font-size: 1.1rem;
      font-weight: bold;
      position: absolute;
      top: 0;
      left: 0;
    }

    img{
      width: 100px;
      display: inline-block;
      margin: 0 6px 0 0;
      vertical-align: bottom;

      @include breakpoint(pc){
        width: 146px;
      }
    }
  }

  &__navBtn{
    position: absolute;
    top: 20px;
    right: 17px;
    width: 24px;
    height: 24px;
    background-image: url('../images/common/nav_btn_open.png');
    background-size: 100%;

    &.is-open{
      background-image: url('../images/common/nav_btn_close.png');
    }

    @include breakpoint(pc){
      display: none;
    }
  }

  &__contents{
    display: none;
    background-color: #6C7680;
    margin: 0 -10px;
    padding: 15px 10px 20px;

    &.is-show{
      display: block;
    }

    @include breakpoint(pc){
      display: block;
      background-color: #fff;
      margin: 0;
      padding: 0;
    }
  }

  &__tel{
    margin: 0 0 8px;
    font-size: 1.6rem;

    @include breakpoint(pc){
      text-align: right;
      color: #333333;
      font-size: 2.4rem;
      font-weight: bold;
    }
  }

  &__btns{
    @include cfx;
    margin: 0 0 15px;

    @include breakpoint(pc){
      float: left;
      width: 421px;
      margin: 0;
    }

    li{
      float: left;
      width: 49.6%;
      background-color: #6697DC;
      border-radius: 4px;

      @include breakpoint(pc){
        width: 210px;
        border-radius: 0;
        transition: 0.5s;

        &:hover{
          opacity: 0.8;
        }
      }

      &:last-child{
        float: right;
        background-color: #FB5227;
      }

      a{
        display: block;
        text-align: center;
        color: #fff;
        text-decoration: none;
        font-size: 1.6rem;
        line-height: 55px;

        @include breakpoint(pc){
          display: block;
          font-size: 1.8rem;
          line-height: 80px;
        }
      }
    }
  }

  &__nav{}

  &__list{
    margin: 0 0 10px;

    @include breakpoint(pc){
      display: table;
    }
  }

  &__item{
    background-color: #fff;
    border-bottom: 1px solid $border-color;

    @include breakpoint(pc){
      display: table-cell;
      width: 200px;
      border-collapse: separate;
      border-right: 1px solid #fff;
      border-left: 1px solid #fff;
      border-bottom: 2px solid $border-color;
    }

    &:last-child{
      border-bottom: none;
    }

    > a{
      display: block;
      line-height: 51px;
      padding: 0 30px 0 17px;
      background-image: url('../images/common/icon_arrow_right3.png');
      background-repeat: no-repeat;
      background-position: 95% center;
      background-position: right 20px center;
      background-size: 10px 14px;
      font-size: 1.6rem;
      text-decoration: none;
      font-weight: bold;
      color: $txt-gray;

      @include breakpoint(pc){
        padding: 0;
        text-align: center;
        font-weight: normal;
        background: none;
        line-height: 57px;

        &:hover{
          font-weight: bold;
        }
      }
    }

    &.current{
      @include breakpoint(pc){
        border-bottom: 2px solid #7DA1CA;
      }

      > a{
        @include breakpoint(pc){
          font-weight: bold;
        }
      }
    }

    &.is-hasChild{

      @include breakpoint(pc){
        position: relative;
      }

      &.is-show{
        background-color: $color-gray;

        @include breakpoint(pc){
          background-color: #fff;
        }
      }

      > a{
        background-image: url('../images/common/icon_arrow_down2.png');
        background-position: 95% center;
        background-position: right 19px center;
        background-size: 14px 10px;

        @include breakpoint(pc){
          background: none;
        }

        &.is-open{
          background-image: url('../images/common/icon_arrow_up2.png');

          @include breakpoint(pc){
            background: none;
          }
        }
      }
    }
  }

  &__childList{
    display: none;
    padding: 0 17px 15px;
    background-color: #fff;

    &.is-show{
      display: block;
    }

    @include breakpoint(pc){
      width: 100%;
      padding: 0;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 2;
      border: 1px solid $border-color;
    }
  }

  &__childItem{
    margin: 0 0 10px;

    @include breakpoint(pc){
      margin: 0;
    }

    a{
      display: block;
      line-height: 34px;
      font-size: 1.6rem;
      padding: 0 0 0 20px;
      text-decoration: none;
      color: $txt-gray;
      background-image: url('../images/common/icon_arrow_right2.png');
      background-repeat: no-repeat;
      background-position: 3px center;
      background-size: 5px 9px;

      @include breakpoint(pc){
        font-size: 1.3rem;
        background: none;
        padding: 0;
        line-height: 40px;
        text-align: center;
        border-top: 1px solid $border-color;
      }
    }
  }

  &__langList{
    @include breakpoint(pc){
      display: inline-block;
    }
  }

  &__langItem{
    background-color: #fff;
    margin: 0 0 10px;

    @include breakpoint(pc){
      background-color: transparent;
      display: inline-block;
      padding: 15px 10px;
      font-size: 1.2rem;
      margin: 0;
    }

    &:last-child{
      margin: 0;
    }

    > a{
      display: block;
      line-height: 40px;
      padding: 0 15px 0 30px;
      text-decoration: none;
      font-size: 1.4rem;
      color: $txt-gray;
      font-weight: bold;
      background-image: url('../images/common/icon_arrow_right.png');
      background-repeat: no-repeat;
      background-position: 15px center;
      background-size: 5px 8px;

      @include breakpoint(pc){
        font-size: 1.2rem;
        padding: 0;
        background: none;
        font-weight: normal;
        text-decoration: underline;
        line-height: 1;
      }
    }
  }

  &__faq{

    @include breakpoint(pc){
      display: inline-block;
      color: #333333;
      padding: 0 0 0 10px;

      a{
        text-decoration: none;
        background-image: url('../images/common/icon_arrow_right.png');
        background-repeat: no-repeat;
        background-position: 0 center;
        background-size: 5px 8px;
        padding: 0 0 0 10px;
        font-size: 1.2rem;
        font-weight: bold;
      }
    }
  }
}
