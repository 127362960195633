@charset "UTF-8";

.c-sliderWrap{
  margin: 0 -20px;
  position: relative;
  padding: 0 10px 30px;

  @include breakpoint(pc){
    padding: 0 0 30px;
    margin: 0;
  }
}

.c-slider{
  position: relative;

  &__item{
    position: relative;
    width: 200px;

    @include breakpoint(pc){
      width: 500px;
    }

    &.slick-current{
      .c-slider__itemInner{
        background-color: transparent;
      }
    }
  }

  &__itemInner{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.6);
  }

  // &__shadow--left{
  //   position: absolute;
  //   width: 230px;
  //   height: 100%;
  //   top: 0;
  //   left: 50%;
  //   margin: 0 0 0 -329px;
  //   background-color: rgba(#000, 0.6);
  //   z-index: 100;

  //   @include breakpoint(pc){
  //     width: 230px;
  //     left: 0;
  //     margin: 0;
  //   }
  // }

  // &__shadow--right{
  //   position: absolute;
  //   width: 230px;
  //   height: 100%;
  //   top: 0;
  //   right: 50%;
  //   margin: 0 -330px 0 0;
  //   background-color: rgba(#000, 0.6);
  //   z-index: 100;

  //   @include breakpoint(pc){
  //     width: 230px;
  //     right: 0;
  //     margin: 0;
  //   }
  // }

  .slick-arrow{
    background-color: transparent;
    border: none;
    font-size: 0;
    position: absolute;
    top: 50%;
    z-index: 101;
    width: 23px;
    height: 43px;
    margin-top: -13px;
    outline: none;
  }

  .slick-prev{
    display: none;

    @include breakpoint(pc){
      background-image: url('../../images/common/slide_prev.png');
      left: 20%;
    }
  }

  .slick-next{
    display: none;

    @include breakpoint(pc){
      background-image: url('../../images/common/slide_next.png');
      right: 20%;
    }
  }

  .slick-dots{
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 0 0 10px;

    li{
      display: inline-block;
      margin: 0 5px;
    }

    button{
      display: block;
      padding: 0;
      width: 11px;
      height: 11px;
      background: none;
      border: none;
      font-size: 0;
      background-color: #EDEFF1;
      border-radius: 100%;
      outline: none;
    }

    .slick-active{
      button{
        background-color: #333333;
      }
    }
  }
}
