@charset "UTF-8";

.p-devFab{

  &__imgs{
    @include cfx;

    img{
      width: 47%;
      display: block;

      &:nth-of-type(1){
        float: left;
      }
      &:nth-of-type(2){
        float: right;
      }
    }
  }
}
