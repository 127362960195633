@charset "UTF-8";


/*doc
---
title: 1.テキスト
name: 1.テキスト
category: txt
---

## テキストです。

```html_example
<p class="c-txt--l">Lサイズのテキストです。余白込みです。</p>
<p class="c-txt--m">Mサイズのテキストです。余白込みです。</p>
<p class="c-txt--s">Sサイズのテキストです。余白込みです。</p>

<p class="c-txt--m is-center">中央寄せ</p>
<p class="c-txt--m is-right">右寄せ</p>
<p class="c-txt--m is-noMargin">マージン無し</p>
<p class="c-txt--m u-fontweight--bold">太字のテキスト</p>
<p class="c-txt--m u-fontcolor--red">赤い文字</p>
<p class="c-txt--l u-letterspacing--m1">文字間詰める</p>


```

*/

.c-txt{

  &--l{
    font-size: 1.6rem;
    line-height: 1.6;
    margin: 0 0 15px;

    @include breakpoint(pc){
      font-size: 1.6rem;
      margin: 0 0 30px;
    }
  }

  &--m{
    font-size: 1.4rem;
    line-height: 1.6;
    margin: 0 0 10px;

    @include breakpoint(pc){
      font-size: 1.4rem;
      margin: 0 0 20px;
    }
  }

  &--s{
    font-size: 1.2rem;
    line-height: 1.6;
    margin: 0 0 5px;

    @include breakpoint(pc){
      font-size: 1.2rem;
      margin: 0 0 10px;
    }
  }
}

.c-txt--l,
.c-txt--m,
.c-txt--s{
  &.is-noMargin{
    margin: 0;
  }
}

.c-txt--l,
.c-txt--m,
.c-txt--s{
  &.is-center{
    text-align: center;
  }
}

.c-txt--l,
.c-txt--m,
.c-txt--s{
  &.is-left{
    text-align: left;
  }
}

.c-txt--l,
.c-txt--m,
.c-txt--s{
  &.is-right{
    text-align: right;
  }
}
