@charset "UTF-8";

/*doc
---
title: 1.リスト
name: 1.リスト
category: list
---

## 普通のリスト

```html_example
<ul class="c-list">
  <li>リスト</li>
  <li>リスト</li>
  <li>リスト</li>
</ul>
```

*/

ul.c-list{

  li{
    line-height: 1.3;
    position: relative;
    padding: 0 0 0 1em;
    margin: 0 0 5px;
    font-size: 1.4rem;

    &:before{
      content: '・';
      display: block;
      width: 1em;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

/*doc
---
title: 2.リスト
name: 2.リスト
category: list
---

## 普通のリスト

```html_example
<ol class="c-list">
  <li>リスト</li>
  <li>リスト</li>
  <li>リスト</li>
</ol>
```

*/

ol.c-list{

  li{
    font-size: 1.4rem;
    line-height: 1.3;
    position: relative;
    margin: 0 0 5px 1.5em;
    list-style-type: decimal;
  }
}


/*doc
---
title: 3.リスト
name: 3.リスト
category: list
---

## FAQのリスト

```html_example
<dl class="c-list--faq">
  <dt>質問が入ります</dt>
  <dd>回答が入ります</dd>
</dl>
```

*/

.c-list--faq{
  padding: 10px 0;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  margin: 0 0 5px;

  @include breakpoint(pc){
    padding: 20px 0;
    margin: 0 0 10px;
  }

  dt{
    min-height: 20px;
    padding: 0 0 0 25px;
    margin: 0 0 10px;
    background-image: url('../images/common/icon_faq-q.png');
    background-repeat: no-repeat;
    background-position: left 2px;
    background-size: 20px;

    @include breakpoint(pc){
      background-position: left top;
      min-height: 40px;
      padding: 6px 0 0 50px;
      margin: 0 0 20px;
      background-size: 40px;
    }
  }

  dd{
    min-height: 20px;
    padding: 0 0 0 25px;
    background-image: url('../images/common/icon_faq-a.png');
    background-repeat: no-repeat;
    background-position: left 2px;
    background-size: 20px;

    @include breakpoint(pc){
      background-position: left top;
      min-height: 40px;
      padding: 8px 0 0 50px;
      background-size: 40px;
      margin: 0;
    }
  }
}








