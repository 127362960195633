@charset "UTF-8";

/*doc
---
title: 1.画像＋テキスト（縦）
name: 1.画像＋テキスト（縦）
category: thumb
---

## 画像とその下にテキストがはいるもの。

```html_example
<div class="c-thumb">
  <div class="c-thumb__img">
    <img src="http://placehold.jp/300x300.png">
  </div>
  <p class="c-thumb__txt">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
</div>
```

*/

.c-thumb{
  @include breakpoint(pc){
  }

  &__img{
    img{
      display: block;
      width: 100%;
    }
  }

  &__txt{
    padding: 10px 10px 15px;
    font-size: 1.2rem;
    line-height: 1.4;

    @include breakpoint(pc){
      padding: 15px;
      font-size: 1.4rem;
      line-height: 1.6;
    }
  }
}


/*doc
---
title: 2.画像＋テキスト（横）
name: 2.画像＋テキスト（横）
category: thumb
---

## 画像とテキストが横並びのもの。

```html_example
<div class="c-thumb--it">
  <div class="c-thumb--it__img">
    <img src="http://placehold.jp/300x300.png">
  </div>
  <p class="c-thumb--it__txt">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
</div>
```

*/


.c-thumb--it{
  @include cfx;

  @include breakpoint(pc){
  }

  &__img{
    width: 30%;
    float: left;
  }

  &__txt{
    float: right;
    width: 70%;
    padding: 0 0 0 10px;
    font-size: 1.4rem;
    line-height: 1.6;

    @include breakpoint(pc){
      padding: 0 0 0 15px;
    }
  }
}


/*doc
---
title: 3.テキスト＋画像（横）
name: 3.テキスト＋画像（横）
category: thumb
---

## 画像とテキストが横並びのもの。

```html_example
<div class="c-thumb--ti">
  <div class="c-thumb--ti__img">
    <img src="http://placehold.jp/300x300.png">
  </div>
  <p class="c-thumb--ti__txt">テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
</div>
```

*/


.c-thumb--ti{
  @include cfx;

  @include breakpoint(pc){
  }

  &__img{
    width: 50%;
    float: right;
  }

  &__txt{
    float: left;
    width: 50%;
    padding: 0 15px 0 0;
    font-size: 1.4rem;
    line-height: 1.6;

    @include breakpoint(pc){
      padding: 0 30px 0 0;
    }
  }
}

.c-thumb--ti.is-wraparound{
  @include cfx;

  @include breakpoint(pc){
  }

  .c-thumb--ti__img{
    width: 50%;
    float: right;
    margin: 0 0 15px 15px;
  }

  .c-thumb--ti__txt{
    float: none;
    width: auto;
    padding: 0 15px 0 0;
    font-size: 1.4rem;
    line-height: 1.6;

    @include breakpoint(pc){
      padding: 0 30px 0 0;
    }
  }
}


