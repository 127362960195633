@charset "UTF-8";

.p-eng{

  @include breakpoint(pc){
  }

  &__table{
    th{
      width: 100%;
      display: block;
      border: none;

      @include breakpoint(pc){
        display: table-cell;
        width: 200px;
        border-bottom: 1px solid #CCCCCC;
      }
    }

    td{
      width: 100%;
      display: block;
      border: none;

      @include breakpoint(pc){
        display: table-cell;
        width: auto;
        border-bottom: 1px solid #CCCCCC;
      }
    }
  }
}
