@charset "UTF-8";

.l-footer{
  background-color: $color-gray;
  padding: 23px 0;

  @include breakpoint(pc){
    padding: 50px 0 60px;
  }

  a:hover{
    text-decoration: underline;
  }

  &__bottom{
    border-top: 1px solid $border-color;
    padding: 20px 0 0;

    @include breakpoint(pc){
      @include cfx;
      padding: 30px 0 0;
    }
  }

  &__logo{
    margin: 0 0 18px;
    font-size: 1rem;
    font-weight: bold;

    @include breakpoint(pc){
      float: left;
    }

    img{
      width: 146px;
      display: inline-block;
      margin: 0 6px 0 0;
      vertical-align: bottom;
    }
  }

  &__copy{

    @include breakpoint(pc){
      float: right;
    }

    p{
      color: $txt-gray;
      line-height: 1.2;
      font-size: 1.4rem;
      margin: 5px 0;
    }
  }
}

