@charset "UTF-8";

.l-wrap{
  width: 100%;
  overflow: hidden;
  padding: 60px 0 0;

  @include breakpoint(pc){
    padding: 0;
  }
}

