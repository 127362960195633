@charset "UTF-8";

.l-sitemap{
  margin: 0 0 43px;

  @include breakpoint(pc){
    @include cfx;
  }

  &__col{

    @include breakpoint(pc){
      width: 21.9%;
      float: left;
      margin: 0 1.7% 0 0;
    }

    &--big{

      @include breakpoint(pc){
        width: 28%;
        margin: 0 2.6% 0 0;
      }
    }

    &:last-child{
      margin: 0;
    }
  }

  &__item{
    border: 1px solid $border-color;
    background-color: #fff;
    margin: 0 0 10px;

    img{
      display: block;
    }

    &--big{

      > a{
        @include cfx;
        display: table;
        width: 100%;
        padding: 4px;
        text-decoration: none;
        font-size: 1.6rem;
        line-height: 1.6;
        color: $txt-gray;
        font-weight: bold;

        @include breakpoint(pc){
          font-size: 1.8rem;
          padding: 4px 6px;
        }
      }

      span{
        display: table-cell;
        vertical-align: middle;

        &:first-child{
          width: 124px;

          @include breakpoint(pc){
            width: 132px;
          }
        }

        &:last-child{
          padding: 0 0 0 20px;
        }
      }
    }

    &--single{

      > a{
        display: block;
        padding: 10px 10px 10px 25px;
        text-decoration: none;
        background-image: url('../images/common/icon_arrow_right.png');
        background-repeat: no-repeat;
        background-position: 15px 17px;
        background-size: 5px 8px;
        font-size: 1.4rem;
        line-height: 1.6;
        color: $txt-gray;
        font-weight: bold;

        @include breakpoint(pc){
          padding: 12px 12px 12px 25px;
          background-position: 15px 19px;
        }
      }
    }

    &--hasChild{

      > a{
        display: block;
        padding: 10px 10px 10px 15px;
        text-decoration: none;
        background-image: url('../images/common/icon_arrow_down.png');
        background-repeat: no-repeat;
        background-position: 95% 17px;
        background-position: right 15px top 15px;
        background-size: 17px 11px;
        font-size: 1.4rem;
        line-height: 1.6;
        color: $txt-gray;
        font-weight: bold;

        @include breakpoint(pc){
          padding: 12px 12px 12px 25px;
          background-image: url('../images/common/icon_arrow_right.png');
          background-repeat: no-repeat;
          background-position: 15px 19px;
          background-size: 5px 8px;

          &.is-noLink{
            &:hover{
              text-decoration: none;
              cursor: default;
            }
          }
        }



        &.is-open{
          background-image: url('../images/common/icon_arrow_up.png');

          @include breakpoint(pc){
            background-image: url('../images/common/icon_arrow_right.png');
          }
        }
      }
    }
  }

  &__childList{
    display: none;
    padding: 0 20px 10px;

    @include breakpoint(pc){
      display: block;
    }

    &.is-show{
      display: block;
    }
  }

  &__childItem{
    margin: 0 0 12px;

    > a{
      padding: 0 0 0 10px;
      display: block;
      font-size: 1.4rem;
      line-height: 1.4;
      background-image: url('../images/common/icon_arrow_right2.png');
      background-repeat: no-repeat;
      background-position: 0 3px;
      background-size: 5px 9px;

      @include breakpoint(pc){
        line-height: 1.6;
        background-position: 5px 7px;
        padding: 0 0 0 15px;
      }
    }
  }
}
