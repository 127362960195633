@charset "UTF-8";

/*doc
---
title: 1.テーブル
name: 1.テーブル
category: table
---

## テーブル（たて）

```html_example
<div class="c-table c-table--col">
  <table>
    <tr>
      <th>見出し</th>
      <th>見出し</th>
    </tr>
    <tr>
      <td>内容</td>
      <td>内容</td>
    </tr>
  </table>
</div>
```

*/

.c-table{
  width: 100%;
  line-height: 1.4;

  @include breakpoint(pc){
  }

  table{
    width: 100%;
    border: 1px solid #CCCCCC;
    vertical-align: top;
  }

  th{
    color: #fff;
    background-color: #666666;
    padding: 15px 20px;
    vertical-align: top;
  }

  td{
    padding: 15px 20px;
    border-bottom: 1px solid #CCCCCC;
    vertical-align: top;
  }
}

.c-table--col{

  tr:first-child{
    th:first-child{
      border-left: none;
    }
    th:last-child{
      border-right: none;
    }
  }

  th{
    border-left: 1px solid #CCCCCC;
    border-right: 1px solid #CCCCCC;
    text-align: center;
    vertical-align: top;
  }

  td{
    border: 1px solid #CCCCCC;
    vertical-align: top;
  }
}


/*doc
---
title: 2.テーブル
name: 2.テーブル
category: table
---

## テーブル（よこ）

```html_example
<div class="c-table c-table--row">
  <table>
    <tr>
      <th>見出し</th>
      <td>内容</td>
    </tr>
    <tr>
      <th>見出し</th>
      <td>内容</td>
    </tr>
  </table>
</div>
```

*/

.c-table--row{

  tr:last-child{
    th{
      border-bottom: none;
    }

    td{
      border-bottom: none;
    }
  }

  th{
    text-align: left;
    border: 1px solid #CCCCCC;
    border-bottom: 1px solid #fff;
    vertical-align: top;
  }

  td{
    border: none;
    border-left: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
    vertical-align: top;
  }
}


/*doc
---
title: 3.テーブル（商品詳細）
name: 3.テーブル（商品詳細）
category: table
---

## 商品詳細ページのみに使うテーブル。指定の列が固定

```html_example
<div class="c-table c-table--detail">
  <table>
    <tr>
      <th>商品コード</th>
      <th>型式</th>
      <th>ネジ</th>
      <th>商品選定項目_1</th>
      <th>商品選定項目_2</th>
      <th>商品選定項目_3</th>
      <th>商品選定項目_4</th>
      <th class="is-fix">CAD図面</th>
    </tr>
    <tr>
      <td>04101101</td>
      <td>VSO-01</td>
      <td>1/8</td>
      <td>商品選定項目_1</td>
      <td>商品選定項目_2</td>
      <td>商品選定項目_3</td>
      <td>商品選定項目_4</td>
      <td class="is-fix">ダウンロード</td>
    </tr>
    <tr>
      <td>04101101</td>
      <td>VSO-01</td>
      <td>1/8</td>
      <td>商品選定項目_1</td>
      <td>商品選定項目_2</td>
      <td>商品選定項目_3</td>
      <td>商品選定項目_4</td>
      <td class="is-fix">ダウンロード</td>
    </tr>
  </table>
</div>

```



*/

.c-table--detail{
  display: none;
}

.c-detailTable{

  &__wrap{
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding-left: 100px;

    &.has-right{
      .c-detailTable__inner{
        position: relative;
        padding-right: 75px;
      }
    }


    @include breakpoint(pc){
      overflow: scroll;
      overflow-y: hidden;
      overflow-x: hidden;
      padding-left: 320px;

      &.is-narrow{
        box-sizing: content-box;
      }

      &.has-right{
        padding-right: 160px;

        .c-detailTable__inner{
          position: static;
          padding-right: 0;
        }
      }
    }
  }

  &__inner{
    width: 100%;
    overflow: scroll;
    overflow-y: hidden;

    @include breakpoint(pc){
      width: auto;
      position: static;
      overflow: auto;
    }
  }

  &__inner2{
    position: relative;

    @include breakpoint(pc){
      position: static;
    }
  }

  &__left{
    width: 150px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

    table{
      width: 100px;
      border: 1px solid #ccc;
      border-collapse: collapse;

      @include breakpoint(pc){
        width: 320px;
      }
    }

    th{
      width: 50px;
      color: #fff;
      background-color: #666666;
      padding: 15px 0;
      border: 1px solid #CCCCCC;
      vertical-align: middle;
      box-sizing: border-box;
      line-height: 1.3;
      font-size: 1.2rem;

      @include breakpoint(pc){
        width: 160px;
        font-size: 1.4rem;
      }
    }

    td{
      width: 50px;
      padding: 15px 5px;
      background-color: #fff;
      border: 1px solid #CCCCCC;
      word-break: break-all;
      line-height: 1.3;
      font-size: 1.2rem;

      @include breakpoint(pc){
        width: 160px;
        padding: 15px 20px;
        font-size: 1.4rem;
      }
    }
  }

  &__main{

    @include breakpoint(pc){
      width: 100%;
      overflow: scroll;
      overflow-y: hidden;
      float: none;
    }

    table{
      margin: 0 auto;
      border: 1px solid #ccc;
      border-collapse: collapse;
    }

    th{
      width: 100px;
      color: #fff;
      background-color: #666666;
      padding: 15px 0;
      border: 1px solid #CCCCCC;
      vertical-align: middle;
      line-height: 1.3;
      font-size: 1.2rem;

      @include breakpoint(pc){
        width: 160px;
        font-size: 1.4rem;
      }
    }

    td{
      width: 100px;
      padding: 15px 15px;
      border: 1px solid #CCCCCC;
      line-height: 1.3;
      font-size: 1.2rem;

      @include breakpoint(pc){
        width: 160px;
        padding: 15px 20px;
        font-size: 1.4rem;
      }
    }
  }

  &__right{
    position: absolute;
    top: 0;
    right: 0;

    @include breakpoint(pc){
      right: 1px;
      border-right: 1px solid #CCCCCC;
    }

    table{
      border: 1px solid #ccc;
      border-collapse: collapse;

      @include breakpoint(pc){
        border-right: none;
      }
    }

    th{
      width: 100px;
      color: #fff;
      background-color: #666666;
      padding: 15px 0;
      border: 1px solid #CCCCCC;
      vertical-align: middle;
      line-height: 1.3;
      font-size: 1.2rem;

      @include breakpoint(pc){
        width: 160px;
        font-size: 1.4rem;
        border-right: none;
      }
    }

    td{
      width: 100px;
      padding: 15px 5px;
      border: 1px solid #CCCCCC;
      line-height: 1.3;
      font-size: 1.2rem;
      vertical-align: middle;

      @include breakpoint(pc){
        width: 160px;
        padding: 15px 20px;
        font-size: 1.4rem;
        border-right: none;
      }
    }
  }
}

table .is-middle{
  vertical-align: middle;
}





/*doc
---
title: 1.テーブルモック
name: 1.テーブルモック
category: table-moc1
---

## プラグイン使用。横幅の可変がうまくいかないので全パターンクラスを用意しておく必要がありそう。

```html_example
<div class="c-table">
  <table id="tablefix">
    <thead>
      <tr>
        <th>商品コード</th>
        <th>型式</th>
        <th>ネジ</th>
        <th>商品選定項目_1</th>
        <th>商品選定項目_2</th>
        <th>商品選定項目_3</th>
        <th>商品選定項目_4</th>
        <th>商品選定項目_5</th>
        <th>商品選定項目_6</th>
        <th>CAD図面</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>04101101</td>
        <td>VSO-01</td>
        <td>1/8</td>
        <td>商品選定項目_1</td>
        <td>商品選定項目_2</td>
        <td>商品選定項目_3</td>
        <td>商品選定項目_4</td>
        <td>商品選定項目_5</td>
        <td>商品選定項目_1</td>
        <td>ダウンロード</td>
      </tr>
      <tr>
        <td>04101101</td>
        <td>VSO-01</td>
        <td>1/8</td>
        <td>商品選定項目_1</td>
        <td>商品選定項目_2</td>
        <td>商品選定項目_2</td>
        <td>商品選定項目_2</td>
        <td>商品選定項目_2</td>
        <td>商品選定項目_1</td>
        <td>ダウンロード</td>
      </tr>
    </tbody>
  </table>
</div>


```

*/


#tablefix{
  width: 1600px;

  th,td{
    width: 160px;
    border: 1px solid #CCCCCC;
  }
}


