@charset "UTF-8";

/*doc
---
category: index
---

# js関連

## 「.js-mh」もしくは「(data-match-height="groupName")」を当てると要素の高さが揃います。

## aタグに「.js-scroll」をつけるとスムーススクロールします。

*/

// =========================================
// Foundation
// =========================================
@import "foundation/_reset.scss";
@import "foundation/_mixins.scss";
@import "foundation/_base.scss";

// =========================================
// Plugin
// =========================================
@import "plugin/_slick.scss";
@import "plugin/_magnific-popup.scss";

// =========================================
// Layout
// =========================================
@import "layout/_wrap.scss";
@import "layout/_header.scss";
@import "layout/_breadcrumb.scss";
@import "layout/_sitemap.scss";
@import "layout/_footer.scss";

// =========================================
// Component
// =========================================
@import "component/_inner.scss";
@import "component/_column.scss";
@import "component/_block.scss";
@import "component/_box.scss";
@import "component/_heading.scss";
@import "component/_btn.scss";
@import "component/_list.scss";
@import "component/_thumb.scss";
@import "component/_table.scss";
@import "component/_link.scss";
@import "component/_txt.scss";
@import "component/_pager.scss";
@import "component/_news.scss";
@import "component/_card.scss";
@import "component/_modal.scss";

@import "component/_contact.scss";
@import "component/_pageTitle.scss";
@import "component/_form.scss";
@import "component/_slider.scss";
@import "component/_searchBox.scss";

// =========================================
// page
// =========================================
@import "page/_com.scss";
@import "page/_con.scss";
@import "page/_devFab.scss";
@import "page/_devFabRoh.scss";
@import "page/_serWho.scss";
@import "page/_itemDetail.scss";
@import "page/_rec.scss";
@import "page/_new.scss";
@import "page/_str.scss";
@import "page/_top.scss";
@import "page/_world--eng.scss";
@import "page/_world--chi.scss";
@import "page/_world--kor.scss";

// =========================================
// Utility
// =========================================
@import "utility/_show.scss";
@import "utility/_font.scss";



