@charset "UTF-8";

.p-str{
    @include breakpoint(pc){}

    &__col{
      @include cfx;
    }

    &__left{

      @include breakpoint(pc){
        width: 48%;
        float: left;
      }
    }

    &__right{

      @include breakpoint(pc){
        width: 48%;
        float: right;
      }
    }

    .c-pageTitle__lead{
      text-align: left;

      @include breakpoint(pc){
        font-size: 3.7rem;
      }

      span{
        font-size: 80%;

        @include breakpoint(pc){
          font-size: 60%;
        }
      }
    }

    .c-pageTitle__text{
      text-align: left;
    }

    &__fade{

      .c-pageTitle__lead{
        position: relative;
        top: 50px;
        opacity: 0;
        transition: 0.4s all 0s;
      }

      .c-pageTitle__text{
        position: relative;
        top: 50px;
        opacity: 0;
        transition: 0.4s all 0.1s;
      }

      .p-str__img{
        position: relative;
        top: 50px;
        opacity: 0;
        transition: 0.4s all 0.3s;
      }

      &.is-show{
        .c-pageTitle__lead,
        .c-pageTitle__text,
        .p-str__img{
          top: 0;
          opacity: 1;
        }
      }
    }
}
