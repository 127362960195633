@charset "UTF-8";

/*doc
---
title: 見出し（h2）
name: 見出し（h2）
category: heading
---

## h2相当の見出しに使用するスタイルです。基本的にページの上部、ぱんくずのすぐ下に使われます。

```html_example
<h2 class="c-heading2">
  <span class="c-heading2__inner">品質管理</span>
</h2>
```

*/

.c-heading2{
  width: 100%;
  background-color: #7DA1CA;
  color: #fff;
  line-height: 1.4;
  padding: 15px 10px;
  margin: 0 auto 45px;

  @include breakpoint(pc){
    margin: 0 auto 90px;
    padding: 30px 0;
  }

  &__inner{
    display: block;
    max-width: 960px;
    margin: 0 auto;
    font-size: 2rem;
    font-family: $font-mincho;

    @include breakpoint(pc){
      font-size: 3.8rem;
      margin: 0 auto;
    }
  }
}


/*doc
---
title: 見出し（h3）
name: 見出し（h3）
category: heading
---

## h3相当の見出しに使用するスタイルです。

```html_example
<h3 class="c-heading3">RoHS対応調書報告書</h3>
```

*/

.c-heading3{
  width: 100%;
  color: #344796;
  font-size: 1.8rem;
  line-height: 1.2;
  border-bottom: 3px solid #3A4299;
  padding: 0 0 5px;
  margin: 0 auto 20px;

  @include breakpoint(pc){
    font-size: 2.8rem;
    margin: 0 auto 40px;
    padding: 0 0 10px;
  }
}


/*doc
---
title: 見出し（h4）
name: 見出し（h4）
category: heading
---

## h4相当の見出しに使用するスタイルです。

```html_example
<h4 class="c-heading4">ホースグリップ</h4>
```

*/

.c-heading4{
  width: 100%;
  color: #333333;
  font-size: 1.6rem;
  line-height: 1.2;
  border-bottom: 1px solid #CCCCCC;
  padding: 0 0 5px;
  margin: 0 auto 7px;

  @include breakpoint(pc){
    font-size: 1.8rem;
    margin: 0 auto 15px;
    padding: 0 0 10px;
  }
}


/*doc
---
title: 見出し（h5）
name: 見出し（h5）
category: heading
---

## h5相当の見出しに使用するスタイルです。

```html_example
<h5 class="c-heading5">JCS製品</h5>
```

*/

.c-heading5{
  width: 100%;
  color: #323232;
  font-size: 1.4rem;
  line-height: 1.2;
  margin: 0 auto 5px;
  padding: 0 0 0 10px;
  border-left: 6px solid #4B4B4B;

  @include breakpoint(pc){
    font-size: 1.6rem;
    margin: 0 auto 10px;
  }
}

