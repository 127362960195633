@charset "UTF-8";

/*doc
---
title: 1.リンク（デフォルト）
name: 1.リンク（デフォルト）
category: link
---

## ボタン

```html_example
<a class="c-link" href="#">
  <span>リンク</span>
</a>
```

*/

.c-link{
  display: block;
  text-decoration: none;
  background-color: #F2FAFF;
  border: 1px solid #3A4199;
  border-radius: 4px;
  //line-height: 1.3;
  //font-size: 1.4rem;
  color: #344796;
  text-align: left;
  padding: 15px;

  span{
    display: block;
    background-image: url('../images/common/icon_arrow_right4.png');
    background-repeat: no-repeat;
    background-position: left 3px;
    background-size: 6px 10px;
    padding: 0 0 0 10px;
  }
}


/*doc
---
title: 2.リンク（デフォルト）
name: 2.リンク（デフォルト）
category: link
---

## ボタン

```html_example
<a class="c-link--page" href="#">リンク</a>
```

*/

.c-link--page{
  display: inline-block;
  text-decoration: none;
  //line-height: 1.3;
  //font-size: 1.4rem;
  color: #344796;
  background-image: url('../images/common/icon_arrow_right4.png');
  background-repeat: no-repeat;
  background-position: left 3px;
    background-size: 6px 10px;
  padding: 0 0 0 10px;

  &:hover{
    text-decoration: underline;
  }
}


/*doc
---
title: 3.リンク（別窓アイコン）
name: 3.リンク（別窓アイコン）
category: link
---

## 別窓リンク（ボタンは別でパーツ作成しています）

```html_example
<a class="c-link--blank" href="#">リンク</a>
```

*/

.c-link--blank{
  background-image: url('../images/common/icon_blank.png');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 15px auto;
  padding-right: 20px;
}


/*doc
---
title: 4.リンク（PDF）
name: 4.リンク（PDF）
category: link
---

## PDFのリンク（ボタンは別でパーツ作成しています）

```html_example
<a class="c-link--pdf" href="#">リンク</a>
```

*/

.c-link--pdf{
  display: inline-block;
  background-image: url('../images/common/icon_pdf.png');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20px auto;
  padding: 0 25px 0 0;
}





