@charset "UTF-8";

.c-pager{
  padding: 40px 0 0;

  ul{
    text-align: center;

    li{
      display: inline-block;
      margin: 0 3px;

      a{
        display: block;
        text-align: center;
        width: 20px;
        line-height: 20px;
        background-color: #F2FAFF;
        border: 1px solid #3A4199;

        &:hover{
          text-decoration: none;
          background-color: #344796;
          color: #ffffff;
        }

        &.is-current{
          background-color: #344796;
          color: #ffffff;
        }
      }
    }
  }
}

