@charset "UTF-8";


.c-modal{

  &__movie{
    width: 100%;
    position: relative;
    padding: 56.23% 0 0;

    iframe{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    @include breakpoint(pc){
      width: 560px;
      margin: 0 auto;
      padding: 56.25% 0 0;

      iframe{
      }
    }
  }

}

.mfp-container{
  padding: 60px 10px 0;
}

.white-popup {
  position: relative;
  background: #FFF;
  padding: 30px 20px;
  width: auto;
  width: 90%;
  max-width: 800px;
  margin: 20px auto;
}
