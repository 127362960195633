@charset "UTF-8";

.p-serWho{
}

.p-serWho__imgs{
  @include cfx;

  .left{
    margin: 0 0 15px;

    @include breakpoint(pc){
      width: 65%;
      margin: 0;
      padding: 0 20px 0 0;
      float: left;
    }

    .c-box--border{
      padding: 30px 10px;
    }
  }

  .right{
    @include cfx;

    @include breakpoint(pc){
      width: 35%;
      padding: 0 0 0 20px;
      float: right;
    }

    img{
      display: block;
      width: 48%;

      @include breakpoint(pc){
        width: 100%;
      }

      &:first-child{
        float: left;

        @include breakpoint(pc){
          float: none;
        }
      }

      &:last-child{
        float: right;

        @include breakpoint(pc){
          float: none;
        }
      }
    }
  }
}
