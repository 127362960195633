@charset "UTF-8";

.p-devFabRoh{

  &__table{

    th{
      &:first-child{
        width: 50px;

        @include breakpoint(pc){
          width: 150px;
        }
      }
    }

    tr{
      td:last-child{
        width: 80px;
        padding-left: 5px;
        padding-right: 5px;

        .c-link--pdf{
          background-position: bottom right;
        }

        @include breakpoint(pc){
          width: 150px;

          br{
            display: none;
          }
        }
      }
    }
  }

  &__spMargin{
    padding-bottom: 15px;

    @include breakpoint(pc){
      padding-bottom: 0;
    }
  }
}
