@charset "UTF-8";

.c-form{

  &__check{}

  &__mail{
    padding: 5px 10px;
    border: 1px solid #CCCCCC;
    border-radius: 2px;
    font-size: 1.6rem;

    @include breakpoint(pc){}
  }

  &__btn{
    width: 100%;
    transition: 0s;
  }
}

