@charset "UTF-8";

.c-pageTitle{
  margin: 0 auto 40px;

  @include breakpoint(pc){
    margin: 0 auto 80px;
  }

  &__lead{
    font-family: $font-mincho;
    font-size: 2rem;
    text-align: center;
    color: #323232;
    margin: 0 auto 15px;
    line-height: 1.4;
    text-align: left;

    br{
      display: none;
    }

    @include breakpoint(pc){
      font-size: 4rem;
      margin: 0 auto 30px;
      text-align: center;

      br{
        display: block;
      }
    }
  }

  &__text{
    color: #323232;
    font-size: 1.2rem;
    line-height: 1.6;
    text-align: left;
    margin: 15px 0 15px;

    br{
      display: none;
    }

    @include breakpoint(pc){
      font-size: 1.6rem;
      margin: 30px 0 30px;
      text-align: center;

      br{
        display: block;
      }
    }
  }
}

