@charset "UTF-8";

/*doc
---
title: 1.ボタン（デフォルト）
name: 1.ボタン（デフォルト）
category: btn
---

## ボタン

```html_example
<a class="c-btn" href="#">デフォルトのボタン</a>
```

*/

.c-btn{
  display: block;
  text-align: center;
  border-radius: 4px;
  background-color: #fff;
  padding: 20px 0 17px;
  box-shadow: 3px 3px 0 0 rgba(#DDDDDD, 1);
  font-size: 1.6rem;
  transition: 0.5s;

  &:hover{
    text-decoration: none;
    opacity: 0.8;
  }
}


/*doc
---
title: 2.ボタン（オレンジ）
name: 2.ボタン（オレンジ）
category: btn
---

## 下部のお問い合わせに使っているボタン

```html_example
<a class="c-btn c-btn--orange" href="#">お問い合わせ</a>
```

*/

.c-btn--orange{
  color: #fff;
  background-color: #F74700;
  box-shadow: 3px 3px 0 0 rgba(#C33700, 1);
}


/*doc
---
title: 3.ボタン（ブルー）
name: 3.ボタン（ブルー）
category: btn
---

## 青いボタン

```html_example
<a class="c-btn c-btn--blue" href="#">お問い合わせ</a>
```

*/

.c-btn--blue{
  color: #fff;
  background-color: #79A3C8;
  box-shadow: 3px 3px 0 0 rgba(#657C97, 1);
}


/*doc
---
title: 4.ボタン（フラット）
name: 4.ボタン（フラット）
category: btn
---

## 青いボタン・影なし

```html_example
<a class="c-btn c-btn--flat" href="#">お問い合わせ</a>
```

*/

.c-btn--flat{
  color: #fff;
  background-color: #3A4299;
  box-shadow: none;
}


/*doc
---
title: 5.ボタン（別窓）
name: 5.ボタン（別窓）
category: btn
---

## 青いボタン・影なし

```html_example
<a class="c-btn c-btn--blank" href="#">お問い合わせ</a>
```

*/

.c-btn--blank{
  border: 1px solid $border-color;
  color: #fff;
  background-color: #3A4299;
  box-shadow: none;
  background-image: url('../images/common/icon_blank-white.png');
  background-repeat: no-repeat;
  background-position: 95% center;
  background-position: right 15px center;
  background-size: 15px auto;
}

/*doc
---
title: 6.ボタン（PDF）
name: 6.ボタン（PDF）
category: btn
---

## 青いボタン・影なし

```html_example
<a class="c-btn c-btn--pdf" href="#">お問い合わせ</a>
```

*/

.c-btn--pdf{
  border: 1px solid $border-color;
  color: #333;
  box-shadow: none;
  background-image: url('../images/common/icon_pdf.png');
  background-repeat: no-repeat;
  background-position: 95% center;
  background-position: right 15px center;
  background-size: 20px auto;
}


/*doc
---
title: 7.ボタン（固定幅）
name: 7.ボタン（固定幅）
category: btn
---

## クラスをつけると固定幅にできる。（クラスなしは100%）
- .is-small で 250px に。
- .is-middle で 500px に。
- .is-wide で 700px に。
- .is-center で中央に寄せに。

```html_example
<a class="c-btn c-btn--blue is-small" href="#">お問い合わせ</a>
<hr>
<a class="c-btn c-btn--orange is-middle" href="#">お問い合わせ</a>
<hr>
<a class="c-btn is-wide" href="#">お問い合わせ</a>
<hr>
<a class="c-btn c-btn--flat is-fix is-center" href="#">お問い合わせ</a>
```

*/


.c-btn.is-small{
  @include breakpoint(pc){
    width: 300px;
  }
}
.c-btn.is-middle{
  @include breakpoint(pc){
    width: 500px;
  }
}
.c-btn.is-wide{
  @include breakpoint(pc){
    width: 700px;
  }
}
.c-btn.is-center{
  margin: 0 auto;
}
.c-btn.is-disabled{
  background-color: #bbb;
  opacity: 0.9;
}


