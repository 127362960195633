@charset "UTF-8";

.u-letterspacing--m1{
  letter-spacing: -0.1em !important;
}

.u-fontcolor--red{
  color: #ff0000 !important;
}

.u-fontweight--bold{
  font-weight: bold !important;
}
