@charset "UTF-8";

.p-itemDetail{

  @include breakpoint(pc){}

  &__btns{
    text-align: center;
    margin-left: -20px;
    margin-right: -20px;
    font-size: 0;

    > p{
      width: calc(100% / 3);
      display: inline-block;
      padding: 0 20px;
    }
  }
}
