@charset "UTF-8";

/*doc
---
title: 1.ボックス（ボーダー）
name: 1.ボックス（ボーダー）
category: box
---

## ボーダー付きボックス

```html_example
<div class="c-box--border">
  <p>コンテンツコンテンツコンテンツコンテンツコンテンツコンテンツコンテンツコンテンツコンテンツ</p>
</div>
```

*/

.c-box--border{
  display: block;
  border: 1px solid #C8C8C8;
  padding: 10px;
}


/*doc
---
title: 2.ボックス（ボーダーと背景）
name: 2.ボックス（ボーダーと背景）
category: box
---

## ボーダーと背景付きボックス

```html_example
<div class="c-box--bgc">
  <p>コンテンツコンテンツコンテンツコンテンツコンテンツコンテンツコンテンツコンテンツコンテンツ</p>
</div>
```

*/

.c-box--bgc{
  display: block;
  background-color: #FAFAFA;
  border: 1px solid #DDDDDD;
  padding: 15px 20px;
}


/*doc
---
title: 3.ボックス（電話番号）
name: 3.ボックス（電話番号）
category: box
---

## ボーダーと背景付きボックス

```html_example
<div class="c-box--tell">
  <div class="c-box--tell__tel">
    <p class="c-box--tell__ttl">フリーコール電話</p>
    <a class="c-box--tell__number" href="tel:0120453802">0120-453-802</a>
    <p class="c-box--tell__time">（平日9:00～18:00）</p>
  </div>
  <div class="c-box--tell__fax">
    <p class="c-box--tell__ttl">フリーコールFAX</p>
    <p class="c-box--tell__number">0120-451-802</p>
    <p class="c-box--tell__time">（24時間受信）</p>
  </div>
</div>
```

*/

.c-box--tell{
  @include cfx;
  width: 100%;
  margin: 0 auto;
  display: block;
  background-color: #FAFAFA;
  border: 3px solid #DDDDDD;
  padding: 20px 0;

  @include breakpoint(pc){
    width: 580px;
    padding: 20px 60px;
  }

  &__tel{
    margin: 0 auto 25px;
    width: 200px;

    @include breakpoint(pc){
      width: auto;
      float: left;
      margin: 0;
    }
  }

  &__fax{
    width: 200px;
    margin: 0 auto;
    @include breakpoint(pc){
      width: auto;
      float: right;
    }
  }

  &__ttl{
    font-size: 1.6rem;
    margin: 0 0 5px;
  }

  &__number{
    display: block;
    font-weight: bold;
    font-size: 2.4rem;
    margin: 0 0 10px;

    &:hover{
      text-decoration: none;
    }
  }

  &__time{
    font-size: 1.6rem;
  }


}



