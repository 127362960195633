@charset "UTF-8";

/*doc
---
title: 1.ブロック
name: 1.ブロック
category: block
---

## ブロックです。

```html_example
<div class="c-block--l" style="padding:20px;background-color:#ccc;">
  <p>Lサイズのブロックです。下にPC:70px、SP:35pxの余白があります。</p>
</div>
<div class="c-block--m" style="padding:20px;background-color:#ccc;">
  <p>Mサイズのブロックです。下にPC:50px、SP:25pxの余白があります。</p>
</div>
<div class="c-block--s" style="padding:20px;background-color:#ccc;">
  <p>Sサイズのブロックです。下にPC:25px、SP:15pxの余白があります。</p>
</div>
```

*/



.c-block{

  &--l{
    margin-bottom: 70px;

    @include breakpoint(pc){
      margin-bottom: 70px;
    }
  }

  &--m{
    margin-bottom: 50px;

    @include breakpoint(pc){
      margin-bottom: 50px;
    }
  }

  &--s{
    margin-bottom: 25px;

    @include breakpoint(pc){
      margin-bottom: 25px;
    }
  }
}
