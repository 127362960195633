@charset "UTF-8";

/*doc
---
title: 1.カラム
name: 1.カラム
category: column
---

## 2カラム、等分です。

```html_example
<div class="c-column c-column--2">
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
</div>
```

*/

.c-column{
  //width: 100%;
}

.c-column--2{

  @include breakpoint(pc){
    @include cfx;
    display: block;
    margin: 0 -20px;
  }

  .c-column__item{
    margin-bottom: 10px;

    @include breakpoint(pc){
      display: block;
      width: 50%;
      float: left;
      padding: 0 20px;

      &:nth-of-type(odd){
        clear: both;
      }
    }
  }
}


/*doc
---
title: 2.カラム
name: 2.カラム
category: column
---

## 2カラム、左大きめです。

```html_example
<div class="c-column c-column--2a">
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
</div>
```

*/

.c-column--2a{

  @include breakpoint(pc){
    display: table;
    margin: 0 -20px;
  }

  .c-column__item{
    margin-bottom: 10px;

    @include breakpoint(pc){
      display: table-cell;
      vertical-align: top;
      padding: 0 20px;

      &:first-child{
        width: 65%;
      }
      &:last-child{
        width: 35%;
      }
    }
  }
}


/*doc
---
title: 3.カラム
name: 3.カラム
category: column
---

## 2カラム、右大きめです。

```html_example
<div class="c-column c-column--2b">
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
</div>
```

*/

.c-column--2b{

  @include breakpoint(pc){
    @include cfx;
    display: table;
    display: block;
    margin: 0 -20px;
  }

  .c-column__item{
    margin-bottom: 10px;

    @include breakpoint(pc){
      vertical-align: top;
      display: table-cell;
      display: block;
      padding: 0 20px;

      &:first-child{
        float: right;
        width: 65%;
      }

      &:last-child{
        float: left;
        width: 35%;
      }
    }
  }

  &Rev{

    @include breakpoint(pc){
      @include cfx;
      display: table;
      display: block;
      margin: 0 -20px;
    }

    .c-column__item{

      @include breakpoint(pc){
        vertical-align: top;
        display: table-cell;
        display: block;
        padding: 0 20px;

        &:first-child{
          float: left;
          width: 35%;
        }

        &:last-child{
          float: right;
          width: 65%;
        }
      }
    }
  }
}


/*doc
---
title: 4.カラム
name: 4.カラム
category: column
---

## 3カラム

```html_example
<div class="c-column c-column--3">
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
</div>
```

*/

.c-column--3{

  @include breakpoint(pc){
    margin: -20px -10px 0;
    @include cfx;
  }

  .c-column__item{
    margin: 0 0 10px;

    @include breakpoint(pc){
      float: left;
      padding: 0 10px;
      margin: 20px 0 0;
      width: 33.3333333%;

      &:nth-of-type(3n+1){
        clear: both;
      }
    }
  }
}


/*doc
---
title: 5.カラム
name: 5.カラム
category: column
---

## 4カラム

```html_example
<div class="c-column c-column--4">
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
</div>
```

*/

.c-column--4{
  @include cfx;

  @include breakpoint(pc){
    margin: -20px -10px 0;
  }

  .c-column__item{
    float: left;
    width: 48%;
    margin: 0 0 10px;

    &:nth-of-type(even){
      float: right;
    }

    @include breakpoint(pc){
      padding: 0 10px;
      margin: 20px 0 0;
      width: 25%;
      width: calc(100% / 4);

      &:nth-of-type(even){
        float: left;
      }

      &:nth-of-type(4n+1){
        clear: both;
      }
    }
  }
}

/*doc
---
title: 6.カラム
name: 6.カラム
category: column
---

## 5カラム

```html_example
<div class="c-column c-column--5">
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
  <div class="c-column__item">
    <p style="background-color:#ccc; padding: 20px;">ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト</p>
  </div>
</div>
```

*/

.c-column--5{
  @include cfx;

  @include breakpoint(pc){
    min-width: 100%;
    display: table;
    margin: -20px -10px 0;
  }

  .c-column__item{
    width: 49%;
    margin-bottom: 10px;

    &:nth-of-type(odd){
      clear: both;
      float: left;
    }

    &:nth-of-type(even){
      float: right;
    }

    @include breakpoint(pc){
      float: left;
      padding: 0 10px;
      margin: 20px 0 0;
      width: 20%;
      width: calc(100% / 5);

      &:nth-of-type(odd){
        clear: none;
        float: left;
      }

      &:nth-of-type(even){
        float: left;
      }

      &:nth-of-type(5n+1){
        clear: both;
      }
    }
  }
}
