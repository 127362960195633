@charset "UTF-8";

/*doc
---
title: 1.タイトル＋画像＋テキスト＋ボタンのセット
name: 1.タイトル＋画像＋テキスト＋ボタンのセット
category: card
---

## セットのやつ

```html_example
<div class="c-card">
  <p class="c-card__ttl">タイトルが入ります。</p>
  <div class="c-card__img">
    <img src="http://placehold.jp/300x160.png">
  </div>
  <p class="c-card__txt">ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキストが入ります。</p>
  <a href="#" class="c-btn c-btn--flat c-card__btn">詳しくはこちら</a>
</div>
```

*/

.c-card{
  @include cfx;
  display: block;
  border: 1px solid #C8C8C8;
  padding: 15px;

  &__ttl{
    @extend .c-heading4;
    font-size: 1.8rem;
  }

  &__img{
    width: 35%;
    margin: 0 0 15px;
    float: right;
  }

  &__txt{
    width: 60%;
    margin: 0 0 15px;
    float: left;
    line-height: 1.6;
  }

  &__btn{
    clear: both;
    margin: 0 auto;
  }
}


