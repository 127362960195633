@charset "UTF-8";

.p-top{

  @include breakpoint(pc){
    .c-inner{
      width: 100%;
      padding: 0 30px;
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  &__ttl{
    font-size: 2.4rem;
    color: #333333;
    font-weight: bold;
    text-align: center;
    position: relative;
    padding: 0 0 20px;
    margin: 0 auto 20px;

    @include breakpoint(pc){
      font-size: 2.8rem;
      margin: 0 auto 50px;
    }

    &:after{
      content: '';
      display: block;
      width: 24px;
      height: 1px;
      background-color: #000000;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -12px;
    }
  }

  &__block--gray{
    background-color: #F6F6F6;
    padding: 30px 0 20px;

    @include breakpoint(pc){
      padding: 60px 0 50px;
    }
  }

  &Slider{
    margin: 0 0 10px;

    @include breakpoint(pc){
      margin: 0 0 100px;
    }

    &__item{
      width: 300px;

      @include breakpoint(pc){
        width: 750px;
      }
    }
  }

  .c-slider__shadow--left{
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 50%;
    margin: 0 0 0 150px;
    background-color: rgba(#000, 0.6);
    z-index: 100;

    @include breakpoint(pc){
      width: 50%;
      left: 0;
      margin: 0 0 0 -375px;
    }
  }

  .c-slider__shadow--right{
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    right: 50%;
    margin: 0 150px 0 0;
    background-color: rgba(#000, 0.6);
    z-index: 100;

    @include breakpoint(pc){
      width: 50%;
      right: 0;
      margin: 0 -375px 0 0;
    }
  }

  .slick-prev{
    left: 50%;
    margin-left: -420px;
  }

  .slick-next{
    right: 50%;
    margin-right: -420px;
  }

  .c-slider .slick-dots{

    @include breakpoint(pc){
      padding: 20px 0 0;
    }
  }

  &__facebook{
    width: 100%;
  }

  &__3col{
    border-left: 1px solid #CCCCCC;
    border-right: 1px solid #CCCCCC;
    background-color: #FFFFFF;
    margin: 0 auto 20px;

    @include breakpoint(pc){
      width: 100%;
      display: table;
      border-collapse: collapse;
      margin: 0 auto 50px;
    }
  }

  &__3colItem{
    padding: 15px 25px;
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;

    @include breakpoint(pc){
      display: table-cell;
      border: 1px solid #CCCCCC;
      padding: 50px 50px;
    }

    .c-btn{
      margin-top: 10px;
    }
  }

  &__3colImg{
    display: block;
    margin: 0 auto 10px;

    @include breakpoint(pc){
      margin: 0 auto 25px;
    }
  }

  &__3colTtl{
    font-size: 1.6rem;
    font-weight: bold;
    color: #333333;
    text-align: center;
    line-height: 1.6;
    margin: 0 auto 10px;

    @include breakpoint(pc){
      font-size: 1.9rem;
      margin: 0 auto 25px;
    }
  }

  &__3colTxt{
    font-size: 1.4rem;
    color: #333333;
    line-height: 1.6;
    text-align: center;
  }

  &__itemInfo{}

  &-itemInfoBlock{
    display: block;
    border: 1px solid #CCCCCC;
    margin: 0 auto 10px;

    @include breakpoint(pc){
      display: table;
      margin: 0 auto 20px;
    }

    &:hover{
      text-decoration: none;
    }

    &__txt{
      padding: 25px 15px 15px;

      @include breakpoint(pc){
        display: table-cell;
        width: 50%;
        text-align: center;
        padding: 0;
        vertical-align: middle;
      }

      img{
        display: block;
        width: 73%;
        margin: 0 auto 15px;

        @include breakpoint(pc){
          width: 50%;
        }
      }

      p{
        line-height: 1.6;

        br{
          display: none;

          @include breakpoint(pc){
            display: block;
          }
        }
      }
    }

    &__img{

      @include breakpoint(pc){
        display: table-cell;
        width: 50%;
      }

      img{
        display: block;
      }
    }
  }

  &-itemInfoNavi{
    @include cfx;

    .c-column__item{

      a{
        display: block;
        border: 1px solid #CCCCCC;
      }
    }

    &__ttl{
      display: block;
      background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlMWUxZTQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
      background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(225,225,228,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(225,225,228,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(225,225,228,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      text-align: center;
      font-size: 1.4rem;
      line-height: 60px;
      font-weight: bold;

      @include breakpoint(pc){
        font-size: 1.6rem;
      }
    }

    &__img{
      display: block;
    }
  }

  &__service{
    @include cfx;

    @include breakpoint(pc){
    }
  }

  &__serviceItem{
    width: 50%;
    float: left;

    &:nth-of-type(odd){
      clear: both;
    }

    @include breakpoint(pc){
      width: 22%;
      margin: 0 4% 30px 0;

      &:nth-of-type(odd){
        clear: none;
      }

      &:nth-of-type(4n){
        margin: 0 0 30px;
      }

      &:nth-of-type(4n+1){
        clear: both;
      }
    }

    a{
      display: block;
      border: 1px solid #CCCCCC;
      padding: 10px 3px;

      @include breakpoint(pc){
        padding: 25px;

      }

      span{
        display: block;
        text-align: center;
        font-size: 1.4rem;
        color: #333333;
        line-height: 1.2;
        margin: 0 auto 5px;

        @include breakpoint(pc){
          font-size: 1.6rem;
        }
      }
    }

    &:nth-of-type(1){
      span{
        @include breakpoint(pc){
          padding-top: 0.5em;
        }
      }
    }

    &:nth-of-type(2){
      span{
        @include breakpoint(pc){
          padding-top: 0.5em;
        }
      }
    }

    &:last-child{
      span{
        padding-top: 0.5em;
      }
    }
  }

  &__bnr{
    .c-column__item{
      margin-bottom: 10px;

      a{
        display: block;
        position: relative;
      }

      span{
        width: 100px;
        height: 100px;
        text-align: center;
        line-height: 100px;
        font-size: 1.4rem;
        color: #333333;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-color: rgba(#fff, 0.8);
        border-radius: 100%;
        font-weight: bold;

        @include breakpoint(pc){
          width: 130px;
          height: 130px;
          line-height: 130px;
          font-size: 1.6rem;
        }
      }
    }
  }
}
