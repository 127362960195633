@charset "UTF-8";

// break point
@mixin breakpoint($point) {
  @if $point == pc {
    @media (min-width:769px) { @content ; }
  }
  @if $point == wide {
    @media (min-width:1200px) { @content ; }
  }
}

//clearfix
@mixin cfx{
	&:after{
		content: '';
		display: block;
		clear: both;
	}
}
